import React, { useEffect, useRef } from 'react';

export default function ZoomBar(props) {
  const {
    zoomMinMax,
    setZoomLevel,
    zoomLevelRef,
    validZoomLevels
  } = props;

  const zoomMin = zoomMinMax[0];
  const zoomMax = zoomMinMax[1];
  const inputContainerRef = useRef(null);
  const inputRef = useRef(null);
  const zoomBarLength = `${1 + validZoomLevels.length * 1.375}rem`;

  // mouse wheel event handler on SvgLayer.js
  function handleChange(e) {
    zoomLevelRef.current = Number(e.target.value);
    setZoomLevel(Number(e.target.value));
  }

  useEffect(() => {
    inputContainerRef.current.style.height = zoomBarLength;
    inputRef.current.style.width = zoomBarLength;
    inputRef.current.style.marginTop = zoomBarLength;
  }, []);

  return (
    <div className="ZoomBar">
      <button
        type="button"
        onClick={() => {
          if (zoomLevelRef.current < zoomMax) {
            zoomLevelRef.current += 1;
            setZoomLevel(zoomLevelRef.current);
          }
        }}
      >
        <svg id="svg__plus" xmlns="http://www.w3.org/2000/svg" width="24.284" height="24.284" viewBox="0 0 24.284 24.284">
          <line id="Line_1" data-name="Line 1" y2="24.284" transform="translate(12.142)" />
          <line id="Line_2" data-name="Line 2" y2="24.284" transform="translate(24.284 12.142) rotate(90)" />
        </svg>
      </button>
      <div className="inputContainer" ref={inputContainerRef}>
        <div className="zoombar-dots">
          {validZoomLevels.map((dot, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="zoombar-dot" />
          ))}
        </div>
        <input
          type="range"
          name="zoomLevel"
          className="zoomLevel"
          id="zoomLevel"
          ref={inputRef}
          value={zoomLevelRef.current}
          min={zoomMin}
          max={zoomMax}
          step={1}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <button
        type="button"
        onClick={() => {
          if (zoomLevelRef.current > zoomMin) {
            zoomLevelRef.current -= 1;
            setZoomLevel(zoomLevelRef.current);
          }
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="21.046" height="4" viewBox="0 0 21.046 4">
          <line id="svg__minus" x2="21.046" transform="translate(0 2)" fill="none" stroke="#fff" strokeWidth="4" />
        </svg>
      </button>
    </div>
  );
}
