import React, { useEffect, useRef } from 'react';
import { gsap, TextPlugin } from 'gsap/all';

gsap.registerPlugin(TextPlugin);

export default function EndLabel(props) {
  const { i, keyProps } = props;
  const {
    endPtCoords,
    canVar,
    zoomLevel,
    bzs,
    zsf,
    lineFocusedRef,
    lineFocused,
    setLineFocused,
    themeList,
    minMaxRow,
    isMobile
  } = keyProps;

  const boxRef = useRef(null);
  const textRef = useRef(null);
  const arrowRef = useRef(null);

  function defineLabelClassName() {
    const labelClassName = `end-label end-label-${i}`;
    switch (zoomLevel) {
      case 0:
        return `${labelClassName} end-label-zoom-0`;
      case 1:
        return `${labelClassName} end-label-zoom-1`;
      case 2:
        return `${labelClassName} end-label-zoom-2`;
      case 3:
        return `${labelClassName} end-label-zoom-3`;
      case 4:
        return `${labelClassName} end-label-zoom-4`;
      case 5:
        return `${labelClassName} end-label-zoom-5`;
      default:
        throw new Error('Invalid zoomLevel');
    }
  }

  function defineLabelZoomShiftFactor() {
    const zoomScaledCellSize = zoomLevel ? canVar.cellSize : (canVar.width / (canVar.numCols + 1));
    const elHeight = window.getComputedStyle(boxRef.current).getPropertyValue('height');
    const elHeightNumber = Number(elHeight.substring(0, elHeight.length - 2));
    return zoomLevel ? (elHeightNumber - zoomScaledCellSize) / 2
      : (elHeightNumber - zoomScaledCellSize) / 2;
  }

  function globalOverviewVerticalAdjust() {
    const halfOfWindowHeight = window.innerHeight / 2;
    const zoomScaledCellSize = (canVar.width / (canVar.numCols + 1));
    const halfOfLinesHeight = ((((minMaxRow[1] - minMaxRow[0]) / 2) + minMaxRow[0])
      * zoomScaledCellSize);
    return halfOfWindowHeight - halfOfLinesHeight;
  }

  function animateEndLabel() {
    const labelClosedText = isMobile && !zoomLevel ? `${i + 1}` : `Line ${i + 1}`;
    const labelOpenText = themeList[i];

    if (textRef.current.textContent === labelClosedText) {
      gsap.to(textRef.current, { text: labelOpenText, left: 200, transition: 0.75 });
      gsap.to(arrowRef.current, { rotate: 180 });
    } else {
      gsap.to(textRef.current, { text: labelClosedText, left: 0, transition: 0.75 });
      gsap.to(arrowRef.current, { rotate: 0 });
    }
  }

  function handleLabelClick(e) {
    // pulls theme index from target
    const theme = e.target.dataset.themeindex;

    // updates lineFocused state and ref
    let lineFocusedStage = lineFocusedRef.current;
    if (lineFocusedStage.includes(false)) {
      lineFocusedStage = [true, true, true, true, true, true, true];
    } else {
      lineFocusedStage = [false, false, false, false, false, false, false];
      lineFocusedStage[theme] = true;
    }
    lineFocusedRef.current = lineFocusedStage;
    setLineFocused(lineFocusedStage);

    if (isMobile && !zoomLevel) return;
    animateEndLabel();
  }

  const topValue = endPtCoords.current[i][0] * bzs.cellSize * zsf.cellSize[zoomLevel];
  const leftValue = (endPtCoords.current[i][1] + 1) * bzs.cellSize * zsf.cellSize[zoomLevel];

  useEffect(() => {
    document.getElementById(`end-label-${i}`).className = defineLabelClassName(
      zoomLevel,
      lineFocused
    );
    document.getElementById(`end-label-${i}`).style.top = zoomLevel ? `${
      topValue - defineLabelZoomShiftFactor()}px`
      : `${
        endPtCoords.current[i][0] * (canVar.width / (canVar.numCols + 1))
          - defineLabelZoomShiftFactor() + globalOverviewVerticalAdjust()
      }px`;
    document.getElementById(`end-label-${i}`).style.left = zoomLevel ? `${leftValue}px` : `${((endPtCoords.current[i][1] + 1) * canVar.cellSize)}px`;
  }, [zoomLevel, lineFocused]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      ref={boxRef}
      id={`end-label-${i}`}
      className={defineLabelClassName(zoomLevel, lineFocused)}
      data-themeindex={i}
      style={{ top: `${topValue}px`, left: `${leftValue}px` }}
      onClick={handleLabelClick}
      role="button"
      tabIndex={0}
    >
      <p ref={textRef} className="lineAnnotation" data-themeindex={i}>{isMobile && !zoomLevel ? `${i + 1}` : `Line ${i + 1}`}</p>
      {isMobile && !zoomLevel ? null : (
        <svg
          ref={arrowRef}
          data-themeindex={i}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 6.445 19.744"
        >
          <path
            id="action-arrow-right"
            d="M867.132,215.086l4.435,9.447-4.435,9.447"
            transform="translate(-866.227 -214.661)"
          />
        </svg>
      )}
    </div>
  );
}
