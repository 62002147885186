import React, { useEffect } from 'react';

export default function NodesLabelsCanvas(props) {
  const {
    nodeStrokeWidth,
    nodeRadius,
    themeData,
    onOffs,
    canVar,
    nodeLists,
    lineFocused,
    zoomLevel,
    validZoomLevels,
    baseZoomStats,
    zoomScaleFactors,
    adjustForZoomLevel,
    nodesLabelsCanvasRef,
    categoryToColor,
    nodeMatrix,
    refreshCount,
    minMaxRow
  } = props;

  const vzl = validZoomLevels;
  const bzs = baseZoomStats;
  const zsf = zoomScaleFactors;

  function defineNodeYCoord(row) {
    const halfOfWindowHeight = window.innerHeight / 2;
    // + 2 is a magic number to align nodes with line
    // @ zoom level 0
    const zoomScaledCellSize = (canVar.width / (canVar.numCols + 2));
    const halfOfLinesHeight = ((((minMaxRow[1] - minMaxRow[0]) / 2) + minMaxRow[0])
      * zoomScaledCellSize);
    const yCoord = zoomLevel ? row * canVar.cellSize + canVar.cellSize / 2
      : row * canVar.cellSize + (halfOfWindowHeight - halfOfLinesHeight);
    return yCoord;
  }

  function drawMidIntPts(
    ctx,
    nodeListsOriginal,
    // eslint-disable-next-line no-shadow
    nodeLists,
    themeIndex,
    onOff
  ) {
    for (let i = 0; i < nodeLists[themeIndex].length; i += 1) {
      // i = ptIndex
      const cell = nodeLists[themeIndex][i];
      if (!cell.coords) throw new Error('Null coords at:', cell, nodeLists);
      const row = cell.coords[0];
      const col = cell.coords[1];

      if (onOff && (cell.type === 'intPt' || cell.type === 'midPt')) {
        const lineWidthScaled = zoomLevel >= bzs.baseLevel
          ? nodeStrokeWidth + (zoomLevel - bzs.baseLevel) * 2 + 2
          : 0;
        ctx.lineWidth = lineWidthScaled;
        ctx.fillStyle = 'white';
        if (cell.data.theme.length > 1) {
          ctx.strokeStyle = lineFocused[
            nodeListsOriginal.indexOf(nodeLists[themeIndex])
          ]
            ? 'black'
            : 'lightgrey';
        } else {
          ctx.strokeStyle = lineFocused[
            nodeListsOriginal.indexOf(nodeLists[themeIndex])
          ]
            ? categoryToColor(cell.data.theme[0].title)
            : 'lightgrey';
        }
        if (
          cell.data.theme
            .map((theme) => theme.title)
            .includes(
              themeData[nodeListsOriginal.indexOf(nodeLists[themeIndex])].node
                .title
            )
        ) {
          // confirm pt is of canvasTheme
          const nodeRadiusScaled = zoomLevel >= 3
            ? nodeRadius + (zoomLevel - bzs.baseLevel) * 4 + 4
            : nodeRadius + (zoomLevel - bzs.baseLevel) * 2 - 1;
          ctx.beginPath();
          ctx.arc(
            col * canVar.cellSize + canVar.cellSize / 2,
            defineNodeYCoord(row),
            nodeRadiusScaled,
            0,
            Math.PI * 2
          );
          ctx.fill();
          if (lineWidthScaled) ctx.stroke();
        }
      }
    }
  }

  // eslint-disable-next-line no-shadow
  function isDrawable(node, themeIndex, nodeLists) {
    // prevent duplicates by only allowing last instance of intPt to be drawn
    let isDrawableCheck;
    if (node.type === 'intPt') {
      isDrawableCheck = themeIndex
        === Math.max(
          ...node.data.theme.map((theme) => nodeLists
            .map((nodeList) => nodeList[0].data.title)
            .indexOf(theme.title))
        );
    }
    return isDrawableCheck;
  }

  // eslint-disable-next-line no-shadow
  function isValidMidOrIntPt(node, onOffs, themeIndex, nodeLists) {
    const isValidIntPt = node.type === 'intPt' && isDrawable(node, themeIndex, nodeLists);
    const isValid = (node.type === 'midPt' || isValidIntPt) && onOffs;
    return isValid;
  }

  function retrieveZoomLevelSettings() {
    let fontWeight;
    let fontSize;
    let labelLeading;
    let maxLabelWidth;
    let labelStrokeWidth;
    let nodeLabelMargin;
    let topLabelMargin;
    let bottomLabelMargin;
    let nodeRadiusValue;
    let nodeStrokeWidthValue;

    switch (zoomLevel) {
      case 0:
        fontWeight = 700;
        fontSize = 0;
        labelLeading = 0;
        maxLabelWidth = 140;
        labelStrokeWidth = 0;
        nodeLabelMargin = 19;
        topLabelMargin = 0;
        bottomLabelMargin = 0;
        nodeRadiusValue = 3;
        nodeStrokeWidthValue = 0;
        break;
      case 1:
        fontWeight = 700;
        fontSize = 8;
        labelLeading = 8.25;
        maxLabelWidth = 44;
        labelStrokeWidth = 3;
        nodeLabelMargin = 4;
        topLabelMargin = 0;
        bottomLabelMargin = 0;
        nodeRadiusValue = 4;
        nodeStrokeWidthValue = 0;
        break;
      case 2:
        fontWeight = 700;
        fontSize = 11.5;
        labelLeading = 14;
        maxLabelWidth = 82;
        labelStrokeWidth = 3.5;
        nodeLabelMargin = 4;
        topLabelMargin = 0;
        bottomLabelMargin = 0;
        nodeRadiusValue = 7;
        nodeStrokeWidthValue = 0;
        break;
      case 3:
        fontWeight = 700;
        fontSize = 18;
        labelLeading = 20;
        maxLabelWidth = 140;
        labelStrokeWidth = 4;
        nodeLabelMargin = 5;
        topLabelMargin = 0;
        bottomLabelMargin = 0;
        nodeRadiusValue = 14;
        nodeStrokeWidthValue = 8;
        break;
      case 4:
        fontWeight = 700;
        fontSize = 21;
        labelLeading = 23;
        maxLabelWidth = 150;
        labelStrokeWidth = 5;
        nodeLabelMargin = 16;
        topLabelMargin = 0;
        bottomLabelMargin = 0;
        nodeRadiusValue = 18;
        nodeStrokeWidthValue = 10;
        break;
      case 5:
        fontWeight = 700;
        fontSize = 24;
        labelLeading = 27;
        maxLabelWidth = 175;
        labelStrokeWidth = 4;
        nodeLabelMargin = 16;
        topLabelMargin = 0;
        bottomLabelMargin = 0;
        nodeRadiusValue = 22;
        nodeStrokeWidthValue = 12;
        break;
      default:
        throw new Error(`Invalid zoomLevel: ${zoomLevel}`);
    }

    const fontSizeString = `${fontSize}px`;
    const fontFamily = 'source-han-sans-korean, sans-serif';
    const font = `${fontWeight} ${fontSizeString} ${fontFamily}`;

    return {
      zoomLevel,
      baseZoomLevel: bzs.baseLevel,
      maxZoomLevel: bzs.maxZoomLevel,
      fontSize,
      font,
      labelLeading,
      maxLabelWidth,
      labelStrokeWidth,
      nodeLabelMargin,
      topLabelMargin,
      bottomLabelMargin,
      nodeRadius: nodeRadiusValue,
      nodeStrokeWidth: nodeStrokeWidthValue
    };
  }

  function defineNodeLabelTypography(ctx, zoomLevelSettings, lineFocusedBool) {
    const zls = zoomLevelSettings;
    ctx.font = zls.font;
    ctx.fillStyle = lineFocusedBool ? 'black' : 'lightgrey';
    ctx.strokeStyle = 'white'; // text stroke color
    ctx.lineWidth = zls.labelStrokeWidth;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
  }

  function generateNodeLabelText(node) {
    const regexString1 = /(^.+) \(.+\)$/;
    const regexString2 = /^.+$/;
    const nodeLabelText = node.data.title.match(regexString1)
      ? node.data.title.match(regexString1)[1]
      : node.data.title.match(regexString2);
    return nodeLabelText;
  }

  function checkForProblemNode(node) {
    // check nodeMatrix for node at [coords - ptSpacing]
    const potentialProblemNodeExists = nodeMatrix[node.coords[0]][node.coords[1]
      - canVar.ptSpacing].data
      || nodeMatrix[node.coords[0] - 2][node.coords[1]].data;
    if (!potentialProblemNodeExists) return false;
    if (
      nodeMatrix[node.coords[0]][node.coords[1] - canVar.ptSpacing].label
      === 'bottom'
    ) { return false; }
    return true;
  }

  function addLineBreaksToNodeLabelText(ctx, nodeLabelText, zoomLevelSettings) {
    const nodeLabelWords = nodeLabelText.index === 0
      ? nodeLabelText[0].split(' ')
      : nodeLabelText.split(' ');
    let line;
    const arrayOfLines = [];
    const maxWidthInPx = zoomLevelSettings.maxLabelWidth;
    for (let i = 0; i < nodeLabelWords.length; i += 1) {
      if (!line) {
        if (ctx.measureText(nodeLabelWords[i]) > maxWidthInPx) {
          arrayOfLines.push(nodeLabelWords[i]);
        } else {
          line = nodeLabelWords[i];
        }
      } else if (
        ctx.measureText(`${line} ${nodeLabelWords[i]}`).width > maxWidthInPx
      ) {
        arrayOfLines.push(line);
        line = nodeLabelWords[i];
      } else {
        line = `${line} ${nodeLabelWords[i]}`;
      }
      if (i === nodeLabelWords.length - 1) {
        if (line) arrayOfLines.push(line);
        line = null;
      }
    }
    return arrayOfLines;
  }

  function checkForLateralCrash(
    ctx,
    node,
    nodeLabelTextLines,
    zoomLevelSettings
  ) {
    const zls = zoomLevelSettings;

    // find nodeLabel width of current node
    const currentNodeLabelWidth = Math.max(
      ...nodeLabelTextLines.map((textLine) => ctx.measureText(textLine).width)
    );
    const currentNodeLabelLeftEnd = node.coords[1] * canVar.cellSize
      + canVar.cellSize / 2
      - currentNodeLabelWidth / 2;

    // find nodeLabel width of node in question
    const problemNode = nodeMatrix[node.coords[0]][node.coords[1] - canVar.ptSpacing];
    if (problemNode.length === 0) return false;
    const problemNodeLabelText = generateNodeLabelText(problemNode);
    const problemNodeLabelTextLines = addLineBreaksToNodeLabelText(
      ctx,
      problemNodeLabelText,
      zoomLevelSettings
    );
    const problemNodeLabelWidth = Math.max(
      ...problemNodeLabelTextLines.map(
        (textLine) => ctx.measureText(textLine).width
      )
    );
    const problemNodeLabelRightEnd = problemNode.coords[1] * canVar.cellSize
      + canVar.cellSize / 2
      + problemNodeLabelWidth / 2;

    if (
      currentNodeLabelLeftEnd
      > problemNodeLabelRightEnd + zls.nodeLabelMargin
    ) { return false; }
    return true;
  }

  function checkForVerticalCrash(
    ctx,
    node,
    nodeLabelTextLines,
    zoomLevelSettings
  ) {
    const zls = zoomLevelSettings;
    const nltl = nodeLabelTextLines;

    const currentNodeLabelTopEnd = node.coords[0] * canVar.cellSize
      - zls.labelLeading * nltl.length
      + zls.topLabelMargin;

    // find nodeLabel width of node in question
    // -2 because this is the problem range
    const problemNode = nodeMatrix[node.coords[0] - 2][node.coords[1]];
    if (problemNode.length === 0) return false;
    const problemNodeLabelText = generateNodeLabelText(problemNode);
    const problemNodeLabelTextLines = addLineBreaksToNodeLabelText(
      ctx,
      problemNodeLabelText,
      zoomLevelSettings
    );

    let problemNodeLabelBottomEnd = problemNode.coords[0] * canVar.cellSize
      + zls.nodeRadius * 2
      + zls.nodeStrokeWidth * 2;
    if (problemNode.label === 'bottom') {
      problemNodeLabelBottomEnd = problemNode.coords[0] * canVar.cellSize
        + zls.nodeRadius * 2
        + zls.nodeStrokeWidth * 2
        + zls.labelLeading * problemNodeLabelTextLines.length;
    }
    if (
      currentNodeLabelTopEnd
      > problemNodeLabelBottomEnd + zls.nodeLabelMargin
    ) { return false; }
    return true;
  }

  function checkForNodeLabelCrash(
    ctx,
    node,
    nodeLabelTextLines,
    zoomLevelSettings
  ) {
    const lateralCrash = checkForLateralCrash(
      ctx,
      node,
      nodeLabelTextLines,
      zoomLevelSettings
    );
    const verticalCrash = checkForVerticalCrash(
      ctx,
      node,
      nodeLabelTextLines,
      zoomLevelSettings
    );
    if (lateralCrash || verticalCrash) return true;
    return false;
  }

  function determineNodeLabelYBottom(zoomLevelSettings, nodeRow) {
    const zls = zoomLevelSettings;
    const { cellSize } = canVar;
    return (nodeRow + 1) * cellSize + zls.fontSize + zls.bottomLabelMargin;
  }

  function determineNodeLabelYTop(
    zoomLevelSettings,
    nodeRow,
    nodeLabelTextLines
  ) {
    const zls = zoomLevelSettings;
    const { cellSize } = canVar;
    const nltl = nodeLabelTextLines;
    return (
      nodeRow * cellSize
      - zls.labelLeading * (nltl.length - 1)
      + zls.topLabelMargin
    );
  }

  function drawNodeLabels(
    ctx,
    nodeListsOriginal,
    // eslint-disable-next-line no-shadow
    nodeLists,
    themeIndex,
    // eslint-disable-next-line no-shadow
    onOffs
  ) {
    for (let pt = 0; pt < nodeLists[themeIndex].length; pt += 1) {
      const node = nodeLists[themeIndex][pt];

      if (isValidMidOrIntPt(node, onOffs, themeIndex, nodeLists)) {
        const zoomLevelSettings = retrieveZoomLevelSettings();
        defineNodeLabelTypography(
          ctx,
          zoomLevelSettings,
          lineFocused[nodeListsOriginal.indexOf(nodeLists[themeIndex])]
        );

        const nodeLabelText = generateNodeLabelText(node);
        const nodeLabelTextLines = addLineBreaksToNodeLabelText(
          ctx,
          nodeLabelText,
          zoomLevelSettings
        );

        let problemNodeExists;
        if (checkForProblemNode(node)) {
          // check if the problemNode's label overlaps with currentNode's label
          problemNodeExists = checkForNodeLabelCrash(
            ctx,
            node,
            nodeLabelTextLines,
            zoomLevelSettings
          );
        }

        // calc nodeLabelX
        const nodelabelX = node.coords[1] * canVar.cellSize + canVar.cellSize / 2;

        // calc nodeLabelY
        let nodeLabelY;
        if (problemNodeExists) {
          nodeLabelY = determineNodeLabelYBottom(
            zoomLevelSettings,
            node.coords[0]
          );
          node.label = 'bottom';
        } else {
          nodeLabelY = determineNodeLabelYTop(
            zoomLevelSettings,
            node.coords[0],
            nodeLabelTextLines
          );
          node.label = 'top';
        }

        if (zoomLevel > 0) {
          nodeLabelTextLines.forEach((textLine, i) => {
            ctx.strokeText(
              textLine,
              nodelabelX,
              nodeLabelY + zoomLevelSettings.labelLeading * i
            );
            ctx.fillText(
              textLine,
              nodelabelX,
              nodeLabelY + zoomLevelSettings.labelLeading * i
            );
          });
        }
      }
    }
  }

  useEffect(() => {
    const canvas = nodesLabelsCanvasRef.current;
    const ctx = canvas.getContext('2d');

    adjustForZoomLevel(
      zoomLevel,
      vzl,
      bzs,
      zsf,
      'NodesLabelsCanvas.js',
      null,
      canVar,
      null,
      null,
      null,
      nodesLabelsCanvasRef,
      null
    );

    canvas.style.width = zoomLevel
      ? `${String(bzs.width * zsf.cellSize[zoomLevel])}px`
      : `${String(window.innerWidth)}px`;
    canvas.style.height = zoomLevel
      ? `${String(bzs.height * zsf.cellSize[zoomLevel])}px`
      : `${String(window.innerHeight)}px`;
    ctx.canvas.width = zoomLevel
      ? bzs.width * zsf.cellSize[zoomLevel]
      : window.innerWidth;
    ctx.canvas.height = zoomLevel
      ? bzs.height * zsf.cellSize[zoomLevel]
      : window.innerHeight;

    if (!nodeLists) throw new Error('Invalid nodeLists value:', nodeLists);
    const nodeListsSorted = nodeLists.slice().sort((a, b) => (
      lineFocused[nodeLists.indexOf(a)] - lineFocused[nodeLists.indexOf(b)]
    ));

    if (refreshCount.current <= 1) {
      // TYPEKIT script
      <script>
        {(function (d) {
          const config = {
            kitId: 'uok3kii',
            scriptTimeout: 3000,
            async: true,
            active: () => {
              nodeListsSorted.forEach((el, i) => {
                drawMidIntPts(
                  ctx,
                  nodeLists,
                  nodeListsSorted,
                  i,
                  onOffs[i]
                );
                drawNodeLabels(
                  ctx,
                  nodeLists,
                  nodeListsSorted,
                  i,
                  onOffs[i]
                );
              });
            }
          };
          const h = d.documentElement;
          const t = setTimeout(() => {
            h.className = `${h.className.replace(/\bwf-loading\b/g, '')} wf-inactive`;
          }, config.scriptTimeout);
          const tk = d.createElement('script');
          let f = false;
          const s = d.getElementsByTagName('script')[0];
          let a;

          h.className += ' wf-loading';
          tk.src = `https://use.typekit.net/${config.kitId}.js`;
          tk.async = true;
          // eslint-disable-next-line no-multi-assign
          tk.onload = tk.onreadystatechange = function () {
            // eslint-disable-next-line react/no-this-in-sfc
            a = this.readyState;
            if (f || (a && a !== 'complete' && a !== 'loaded')) return;
            f = true;
            clearTimeout(t);
            try {
              // eslint-disable-next-line no-undef
              Typekit.load(config);
            } catch (e) {
              throw new Error('Something happened with TypeKit.');
            }
          };
          s.parentNode.insertBefore(tk, s);
        }(document))}
      </script>;
    } else {
      // unblocked nodesLabels draw
      nodeListsSorted.forEach((el, i) => {
        drawMidIntPts(
          ctx,
          nodeLists,
          nodeListsSorted,
          i,
          onOffs[i]
        );
        drawNodeLabels(
          ctx,
          nodeLists,
          nodeListsSorted,
          i,
          onOffs[i]
        );
      });
    }
  }, [onOffs, lineFocused, zoomLevel]);

  return (
    <canvas ref={nodesLabelsCanvasRef}>
      This is a visual canvas element. For a textual view of site information,
      go to the works, media, or about pages.
    </canvas>
  );
}
