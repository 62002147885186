import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import HomeLayout from '../components/Index/HomeLayout';
import NavigationSet from '../components/NavigationSet';
import Seo from '../components/seo';
import BackgroundLayer from '../components/Index/Layers/BackgroundLayer';
import LinesCanvas from '../components/Index/Layers/LinesCanvas';
import NodesLabelsCanvas from '../components/Index/Layers/NodesLabelsCanvas';
import MenuLayer from '../components/Index/Layers/MenuLayer';
import SvgLayer from '../components/Index/Layers/SvgLayer';
import Widgets from '../components/Index/Widgets/Widgets';

import '../scss/pages/home.scss';
//
function IndexPage() {
  // -------------------------------------------------------
  // << SANITY GRAPHQL QUERY >>
  // -------------------------------------------------------

  const sanityData = useStaticQuery(graphql`
    query {
      allSanityProject(filter: { slug: { current: { ne: "null" } } }) {
        edges {
          node {
            id
            title
            slug {
              current
            }
            theme {
              title
            }
            year
            city {
              title
            }
            country {
              title
            }
            mainImage {
              asset {
                gatsbyImageData(
                  fit: CLIP
                  placeholder: BLURRED
                  height: 176
                )
              }
            }
          }
        }
      }
      allSanityTheme(
        sort: {order: ASC, fields: title}
        filter: { slug: { current: { ne: "null" } } }
      ) {
        edges {
          node {
            title
            slug {
              current
            }
            mainImage {
              asset {
                gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
  `);

  // -------------------------------------------------------
  // << KEY VARIABLES (STATE, REFS, GRAPHQL RESULTS, ETC) >>
  // -------------------------------------------------------

  const projectData = sanityData.allSanityProject.edges;
  const themeData = sanityData.allSanityTheme.edges;

  const zoomTarget = useRef([0, 0]);
  function testIsMobile() {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  }
  const isMobile = testIsMobile();
  const validZoomLevels = isMobile ? [0, 1] : [0, 1, 2, 3, 4, 5];
  const zoomMinMax = [
    Math.min(...validZoomLevels),
    Math.max(...validZoomLevels)
  ];
  const [cellSize, numRows, numCols] = [40, 108, 233];
  const baseZoomStats = {
    minZoomLevel: zoomMinMax[0],
    maxZoomLevel: zoomMinMax[1],
    baseLevel: 3,
    cellSize,
    width: cellSize * numCols,
    height: cellSize * numRows,
    nodeLabelFontSize: 16
  };
  const zoomScaleFactors = {
    cellSize: [0.35, 0.35, 0.65, 1, 1.25, 1.5],
    lineStrokeWidth: 4,
    nodeLabelFontSize: 3
  };

  const [zoomLevel, setZoomLevel] = useState(isMobile ? 1 : baseZoomStats.baseLevel);
  const zoomLevelRef = useRef(zoomLevel);

  const [menuToggle, setMenuToggle] = useState(1);
  const [onOffs, setOnOffs] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true
  ]);
  const [lineFocused, setLineFocused] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true
  ]);
  const lineFocusedRef = useRef(lineFocused);
  const [lineStrokeWidth, nodeStrokeWidth, nodeRadius] = [22, 6, 10];

  // set canvas variables
  const canVar = {
    cellSize,
    width: baseZoomStats.width,
    height: baseZoomStats.height,
    numRows,
    numCols,
    ptSpacing: 3,
    margin: 4,
    nodeRadius,
    nodeStrokeWidth
  };

  const nodeLists = useRef(null);
  const lineLists = useRef(null);
  const nodeMatrixStable = useRef(null);
  const lineMatrixStable = useRef(null);
  const ran = useRef(0);
  const refreshCount = useRef(0);
  const menuLayerRef = useRef(null);
  const linesCanvasRef = useRef(null);
  const nodesLabelsCanvasRef = useRef(null);
  const backgroundRef = useRef(null);
  const vertLine = useRef(null);
  const horiLine = useRef(null);

  const startPtCoords = useRef([
    [10, 82], // Line 1
    [90, 49], // Line 2
    [36, 22], // Line 3
    [54, 22], // Line 4
    [80, 38], // Line 5
    [76, 22], // Line 6
    [10, 22] // Line 7
  ]);
  const endPtCoords = useRef([
    [25, 175], // Line 1
    [57, 215], // Line 2
    [16, 160], // Line 3
    [74, 215], // Line 4
    [51, 215], // Line 5
    [39, 187], // Line 6
    [90, 215] // Line 7
  ]);

  const categories = [
    themeData[0].node.title, // R
    themeData[1].node.title, // O
    themeData[2].node.title, // Y
    themeData[3].node.title, // G
    themeData[4].node.title, // B
    themeData[5].node.title, // I
    themeData[6].node.title // V
  ];

  // -------------------------------------------------------
  // << UTILITY FUNCTIONS >>
  // -------------------------------------------------------

  // returns an index corresponding to the direction from the prevPt to targetPt
  function testSlope(prevPt, targetPt) {
    const targetPtRow = targetPt.coords[0];
    const targetPtCol = targetPt.coords[1];
    const prevPtRow = prevPt.coords[0];
    const prevPtCol = prevPt.coords[1];

    // cases where slope of connecting line is vertical/undefined
    if (targetPtCol === prevPtCol && targetPtRow - prevPtRow < 0) return 0;
    if (targetPtCol === prevPtCol && targetPtRow - prevPtRow > 0) return 8;

    // cases where slope is not vertical
    const rise = targetPtRow - prevPtRow;
    const run = targetPtCol - prevPtCol;
    const connectingSlope = rise / run;

    if (run > 0 && connectingSlope < -1) return 1;
    if (run > 0 && connectingSlope === -1) return 2;
    if (run > 0 && connectingSlope > -1 && connectingSlope < 0) return 3;
    if (run > 0 && connectingSlope === 0) return 4;
    if (run > 0 && connectingSlope > 0 && connectingSlope < 1) return 5;
    if (run > 0 && connectingSlope === 1) return 6;
    if (run > 0 && connectingSlope > 1) return 7;
    if (run < 0 && connectingSlope < -1) return 9;
    if (run < 0 && connectingSlope === -1) return 10;
    if (run < 0 && connectingSlope > -1 && connectingSlope < 0) return 11;
    if (run < 0 && connectingSlope === 0) return 12;
    if (run < 0 && connectingSlope > 0 && connectingSlope < 1) return 13;
    if (run < 0 && connectingSlope === 1) return 14;
    if (run < 0 && connectingSlope > 1) return 15;
    throw new Error('Cannot find slope region.');
  }

  // returns an array of 1 or 2 values that correspond to indices in legalSpaces
  function getPreferredPaths(slopeRegion) {
    if (slopeRegion % 2 === 0) {
      return [slopeRegion / 2];
    }
    switch (slopeRegion) {
      case 1:
        return [0, 1];
      case 3:
        return [2, 1];
      case 5:
        return [2, 3];
      case 7:
        return [4, 3];
      case 9:
        return [4, 5];
      case 11:
        return [6, 5];
      case 13:
        return [6, 7];
      case 15:
        return [0, 7];
      default:
        throw new Error(`Invalid slopeRegion value ${slopeRegion}`);
    }
  }

  // -------------------------------------------------------
  // << MAIN FUNCTIONS >>
  // -------------------------------------------------------

  function initNodeLists() {
    const projectLists = Array.from({ length: themeData.length }, () => []);

    function pushPtToList(targetArray, coords, data, type, firstPt = null) {
      if (targetArray === null) throw new Error('targetArray for pushPtToList is null');
      if (firstPt !== null) {
        targetArray.push({
          coords,
          data,
          type,
          firstPt
        });
        return;
      }
      targetArray.push({
        coords,
        data,
        type
      });
    }

    // create project lists for each theme
    for (let themeIndex = 0; themeIndex < themeData.length; themeIndex += 1) {
      pushPtToList(
        projectLists[themeIndex],
        null,
        themeData[themeIndex].node,
        'startLabel'
      );
      pushPtToList(
        projectLists[themeIndex],
        startPtCoords.current[themeIndex],
        themeData[themeIndex].node,
        'startPt'
      );

      // append projects to projectLists[themeIndex]
      projectData
        .filter((project) => { // get projects with current themeIndex
          if (project.node.theme.length > 1) {
            let present = false;
            for (let i = 0; i < project.node.theme.length; i += 1) {
              if (
                project.node.theme[i].title === themeData[themeIndex].node.title
              ) {
                present = true;
              }
            }
            return present;
          }
          return (
            project.node.theme[0].title === themeData[themeIndex].node.title
          );
        })
        .sort((a, b) => { // chronological sort
          if (a.node.year[0] > b.node.year[0]) {
            return 1;
          }
          if (a.node.year[0] < b.node.year[0]) {
            return -1;
          }
          return 0;
        })
        .forEach((project, i) => {
          if (i === 0 && project.node.theme.length > 1) {
            pushPtToList(
              projectLists[themeIndex],
              null,
              project.node,
              'intPt',
              true
            );
          } else if (i === 0 && project.node.theme.length === 1) {
            pushPtToList(
              projectLists[themeIndex],
              null,
              project.node,
              'midPt',
              true
            );
          } else if (i !== 0 && project.node.theme.length > 1) {
            pushPtToList(
              projectLists[themeIndex],
              null,
              project.node,
              'intPt',
              false
            );
          } else if (i !== 0 && project.node.theme.length === 1) {
            pushPtToList(
              projectLists[themeIndex],
              null,
              project.node,
              'midPt',
              false
            );
          }
        });
      pushPtToList(
        projectLists[themeIndex],
        endPtCoords.current[themeIndex],
        themeData[themeIndex].node,
        'endPt'
      );
      pushPtToList(
        projectLists[themeIndex],
        null,
        themeData[themeIndex].node,
        'endLabel'
      );
    }
    return projectLists;
  }

  function calcCoords(
    ptLists,
    nodeMatrix
  ) {
    // updates the boolean of if any null values of coords remain in ptLists
    function updateNullCoordTest() {
      return ptLists.flat().map((pt) => pt.coords).reduce((a, b) => Boolean(a) * Boolean(b));
    }

    function findLegalSpaces(ptList, ptIndex) {
      const lastPt = ptList[ptIndex - 1];
      const lastPtRow = lastPt.coords[0];
      const lastPtCol = lastPt.coords[1];

      // adjSpaces
      let adjSpaces;
      try {
        adjSpaces = [
          [lastPtRow - canVar.ptSpacing, lastPtCol],
          [lastPtRow - canVar.ptSpacing, lastPtCol + canVar.ptSpacing],
          [lastPtRow, lastPtCol + canVar.ptSpacing],
          [lastPtRow + canVar.ptSpacing, lastPtCol + canVar.ptSpacing],
          [lastPtRow + canVar.ptSpacing, lastPtCol],
          [lastPtRow + canVar.ptSpacing, lastPtCol - canVar.ptSpacing],
          [lastPtRow, lastPtCol - canVar.ptSpacing],
          [lastPtRow - canVar.ptSpacing, lastPtCol - canVar.ptSpacing]
        ];
      } catch (error) {
        throw new Error(`last point is on edge of canvas: ${lastPt}`);
      }

      // test for margin conditions in adjSpaces
      const marginResults = [1, 1, 1, 1, 1, 1, 1, 1];
      if (
        adjSpaces[0] === 0
        || lastPtRow - canVar.ptSpacing <= 0 + canVar.margin
      ) {
        marginResults[0] = 0;
      }
      if (
        adjSpaces[1] === 0
        || lastPtRow - canVar.ptSpacing <= 0 + canVar.margin
        || lastPtCol + canVar.ptSpacing >= canVar.numCols - 1 - canVar.margin
      ) {
        marginResults[1] = 0;
      }
      if (
        adjSpaces[2] === 0
        || lastPtCol + canVar.ptSpacing >= canVar.numCols - 1 - canVar.margin
      ) {
        marginResults[2] = 0;
      }
      if (
        adjSpaces[3] === 0
        || lastPtCol + canVar.ptSpacing >= canVar.numCols - 1 - canVar.margin
        || lastPtRow + canVar.ptSpacing >= canVar.numRows - 1 - canVar.margin
      ) {
        marginResults[3] = 0;
      }
      if (
        adjSpaces[4] === 0
        || lastPtRow + canVar.ptSpacing >= canVar.numRows - 1 - canVar.margin
      ) {
        marginResults[4] = 0;
      }
      if (
        adjSpaces[5] === 0
        || lastPtRow + canVar.ptSpacing >= canVar.numRows - 1 - canVar.margin
        || lastPtCol - canVar.ptSpacing <= 0 + canVar.margin
      ) {
        marginResults[5] = 0;
      }
      if (
        adjSpaces[6] === 0
        || lastPtCol - canVar.ptSpacing <= 0 + canVar.margin
      ) {
        marginResults[6] = 0;
      }
      if (
        adjSpaces[7] === 0
        || lastPtCol - canVar.ptSpacing <= 0 + canVar.margin
        || lastPtRow - canVar.ptSpacing <= 0 + canVar.margin
      ) {
        marginResults[7] = 0;
      }

      // test for crash conditions in adjSpaces
      const crashResults = marginResults.map((marginResult, i) => {
        if (
          !marginResult
          || nodeMatrix[adjSpaces[i][0]][adjSpaces[i][1]].type
          || nodeMatrix[adjSpaces[i][0]][adjSpaces[i][1]].data
        ) {
          return 0;
        }
        return 1;
      });

      // node adjacency detection test
      const detectionResults = crashResults;
      detectionResults.forEach((detectionResult, i) => {
        if (detectionResult !== 0) {
          // check the corresponding adjSpace
          const currentPt = [adjSpaces[i][0], adjSpaces[i][1]];
          const currentPtRow = currentPt[0];
          const currentPtCol = currentPt[1];
          const immediateAdjSpaces = [
            [currentPtRow - 1, currentPtCol],
            [currentPtRow - 1, currentPtCol + 1],
            [currentPtRow, currentPtCol + 1],
            [currentPtRow + 1, currentPtCol + 1],
            [currentPtRow + 1, currentPtCol],
            [currentPtRow + 1, currentPtCol - 1],
            [currentPtRow, currentPtCol - 1],
            [currentPtRow - 1, currentPtCol - 1]
          ];

          // for each corresponding adjSpace
          const ptCheck = immediateAdjSpaces
            .map((immediateAdjSpace) => Boolean(
              // check its immediate adjSpaces for a node
              nodeMatrix[immediateAdjSpace[0]][immediateAdjSpace[1]].data
            ))
            .reduce(
              (prevTestValue, currentTestValue) => prevTestValue + currentTestValue
            );

          // if any contain a node
          if (ptCheck !== 0) detectionResults[i] = 0;
        }
      });
      if (detectionResults.reduce((a, b) => a + b) === 0) {
        throw new Error('No legal spaces available!');
      }
      return [adjSpaces, detectionResults];
    }

    function findIndicesForForwardAndLateralMotion(slopeRegion) {
      switch (slopeRegion) {
        case 0:
          return [1, 1, 1, 0, 0, 0, 1, 1];
        case 1:
          return [1, 1, 1, 0, 0, 0, 0, 1];
        case 2:
          return [1, 1, 1, 1, 0, 0, 0, 1];
        case 3:
          return [1, 1, 1, 1, 0, 0, 0, 0];
        case 4:
          return [1, 1, 1, 1, 1, 0, 0, 0];
        case 5:
          return [0, 1, 1, 1, 1, 0, 0, 0];
        case 6:
          return [0, 1, 1, 1, 1, 1, 0, 0];
        case 7:
          return [0, 0, 1, 1, 1, 1, 0, 0];
        case 8:
          return [0, 0, 1, 1, 1, 1, 1, 0];
        case 9:
          return [0, 0, 0, 1, 1, 1, 1, 0];
        case 10:
          return [0, 0, 0, 1, 1, 1, 1, 1];
        case 11:
          return [0, 0, 0, 0, 1, 1, 1, 1];
        case 12:
          return [1, 0, 0, 0, 1, 1, 1, 1];
        case 13:
          return [1, 0, 0, 0, 0, 1, 1, 1];
        case 14:
          return [1, 1, 0, 0, 0, 1, 1, 1];
        case 15:
          return [1, 1, 0, 0, 0, 0, 1, 1];
        default:
          throw new Error(
            'Invalid slopeRegion value in findIndicesForForwardAndLateralMotion:',
            slopeRegion
          );
      }
    }

    // find targetPt and select a pathIndex to take to approach targetPt
    function calcMidPtMoveDirection(
      themeIndex,
      ptIndex,
      testResults,
      legalSpaces
    ) {
      // find targetPt
      let targetPt;

      // determine if next non-midPt is intPt or endPt
      const nextCriticalPt = ptLists[themeIndex]
        .slice(ptIndex)
        .find((pt) => pt.type === 'intPt' || pt.type === 'endPt');
      const nextCriticalPtType = nextCriticalPt.type;
      if (!nextCriticalPtType) throw new Error('nextCriticalPtType is falsy', nextCriticalPt);
      if (nextCriticalPtType === 'endPt') targetPt = nextCriticalPt;
      if (nextCriticalPtType === 'intPt') {
        // if 2 themes, head toward lastPt of other theme
        if (nextCriticalPt.data.theme.length === 2) {
          // set targetPt to its own endPt
          targetPt = ptLists[themeIndex].find((pt) => pt.type === 'endPt');
        }

        // if >2 themes, find the midpoint of the rows and cols of the other themes
        if (nextCriticalPt.data.theme.length > 2) {
          // set targetPt to its own endPt
          targetPt = ptLists[themeIndex].find((pt) => pt.type === 'endPt');
        }
      }
      if (!targetPt) throw new Error('targetPt is null!', targetPt);

      // find slope to targetPt
      const prevPt = ptLists[themeIndex][ptIndex - 1];

      // guard against a line coming out of an intPt,
      // selecting that intPt in the intersecting line as a targetPt
      if (
        prevPt.data.title === targetPt.data.title
        && prevPt.type === targetPt.type
      ) {
        // reassign targetPt to line's own endPt
        targetPt = ptLists[themeIndex].find((pt) => pt.type === 'endPt');
      }

      const slopeRegion = testSlope(prevPt, targetPt);

      // find preferred paths from slope
      const prefPaths = getPreferredPaths(slopeRegion);

      // compare preferred paths to legal spaces and select a path to take
      let selectedPathIndex;
      const legalPrefPaths = prefPaths
        .map((path) => testResults[path])
        .reduce((a, b) => a + b);

      // if legal preferred paths exist
      if (legalPrefPaths > 0) {
        // remove illegal prefPaths
        prefPaths.forEach((prefPath, i) => {
          if (testResults[prefPath] === 0) {
            prefPaths.splice(i, 1);
          }
        });

        // get legal path that brings pt closest to targetPt
        const distances = prefPaths.map((prefPath) => {
          const distance = Math.sqrt(
            (targetPt.coords[0] - legalSpaces[prefPath][0]) ** 2
            + (targetPt.coords[1] - legalSpaces[prefPath][1]) ** 2
          );
          return distance;
        });
        selectedPathIndex = prefPaths[distances.indexOf(Math.min(...distances))];
      }

      // if preferred paths are illegal
      if (legalPrefPaths === 0) {
        // choose randomly from legal spaces and the forward and lateral spaces
        const forwardAndLateralIndices = findIndicesForForwardAndLateralMotion(slopeRegion);
        const legalForwardAndLateralIndices = testResults.map(
          (testResult, i) => testResult * forwardAndLateralIndices[i]
        );
        if (legalForwardAndLateralIndices.reduce((a, b) => a * b) === 0) {
          // choose legal space closest to targetPt
          const distances = testResults.map((testResult, i) => {
            if (testResult) {
              const distance = Math.sqrt(
                (legalSpaces[i][0] - targetPt.coords[0]) ** 2
                + (legalSpaces[i][1] - targetPt.coords[1]) ** 2
              );
              return distance;
            }
            return canVar.width;
          });
          selectedPathIndex = distances.indexOf(Math.min(...distances));
        } else {
          // choose the best one?
          selectedPathIndex = legalForwardAndLateralIndices.indexOf(1);
        }
      }
      return selectedPathIndex;
    }

    // remove travelPts at end of function to speed calculation while calculating linePts
    function removeTravelPts() {
      // for each theme in themes
      for (let theme = 0; theme < ptLists.length; theme += 1) {
        // while there are travelPts in theme
        while (ptLists[theme].map((pt) => pt.type).includes('travelPt')) {
          // for each pt in ptLists
          for (let pt = 0; pt < ptLists[theme].length; pt += 1) {
            // if pt is travelPt, ptLists[theme].splice(pt, 1)
            if (ptLists[theme][pt].type === 'travelPt') {
              ptLists[theme].splice(pt, 1);
            }
          }
        }
      }
    }

    // boolean for breaking out of loops and forcing a reroll
    let noGood;

    // boolean to check for null coord values in ptLists
    let ptListNullCoordTest = updateNullCoordTest();

    let ptIndex = 0;
    let themeIndex = 0;
    while (ptListNullCoordTest === 0) {
      // guard clause for when ptIndex > length of ptList at hand
      const ptIsValid = ptIndex < ptLists[themeIndex].length;
      if (!ptIsValid) {
        ptListNullCoordTest = updateNullCoordTest();
        themeIndex += 1;
        if (themeIndex === ptLists.length) {
          themeIndex = 0;
          ptIndex += 1;
        }
        // eslint-disable-next-line no-continue
        continue;
      }

      // init variables for midPt tests
      let legalSpaces; let
        testResults;

      // set startLabel coords
      if (ptLists[themeIndex][ptIndex].type === 'startLabel') {
        // in ptLists
        ptLists[themeIndex][ptIndex].coords = [
          startPtCoords.current[themeIndex][0],
          startPtCoords.current[themeIndex][1] - 1
        ];

        // in nodeMatrix
        nodeMatrix[startPtCoords.current[themeIndex][0]][
          startPtCoords.current[themeIndex][1] - 1
        ] = ptLists[themeIndex][ptIndex];
      }

      // set startPt coords
      if (ptLists[themeIndex][ptIndex].type === 'startPt') {
        // in ptLists
        ptLists[themeIndex][ptIndex].coords = startPtCoords.current[themeIndex];

        // in nodeMatrix
        nodeMatrix[
          startPtCoords.current[themeIndex][0]][startPtCoords.current[themeIndex][1]
        ] = ptLists[themeIndex][ptIndex];
      }

      // set midPt coords
      if (ptLists[themeIndex][ptIndex].type === 'midPt') {
        // << NEW ROUTINE: 8 DIRECTIONS POSSIBLE >>
        // find legal spaces and throw new error if no pts exist
        [legalSpaces, testResults] = findLegalSpaces(
          ptLists[themeIndex],
          ptIndex
        );

        const selectedPathIndex = calcMidPtMoveDirection(
          themeIndex,
          ptIndex,
          testResults,
          legalSpaces
        );

        // place midPt in selected path
        // standard method of placing in ptLists
        ptLists[themeIndex][ptIndex].coords = [
          legalSpaces[selectedPathIndex][0],
          legalSpaces[selectedPathIndex][1]
        ];
        // standard method of placing in nodeMatrix
        nodeMatrix[legalSpaces[selectedPathIndex][0]][
          legalSpaces[selectedPathIndex][1]
        ] = ptLists[themeIndex][ptIndex];
      }

      // set intPt coords
      if (ptLists[themeIndex][ptIndex].type === 'intPt') {
        // << NEW ROUTINE: 8 DIRECTIONS POSSIBLE >>

        // find all instances of current intPt
        const currentIntPtData = ptLists[themeIndex][ptIndex].data;
        const instances = currentIntPtData.theme.map((themeEl) => {
          const currentThemeIndex = themeData
            .map((theme) => theme.node.title)
            .indexOf(themeEl.title);
          return {
            currentThemeIndex,
            ptIndex: ptLists[currentThemeIndex].findIndex(
              (pt) => pt.data.title === currentIntPtData.title
                && (pt.type === 'midPt' || pt.type === 'intPt')
            )
          };
        });

        // check if intPt is already placed
        const alreadyPlaced = instances
          .map((instance) => Boolean(ptLists[instance.currentThemeIndex][instance.ptIndex].coords))
          .reduce((a, b) => a * b);

        // if so, update ptListNullCoordTest and skip to next ptIndex
        if (alreadyPlaced) {
          ptListNullCoordTest = updateNullCoordTest();
          themeIndex += 1;
          if (themeIndex === ptLists.length) {
            themeIndex = 0;
            ptIndex += 1;
          }
          // eslint-disable-next-line no-continue
          continue;
        }

        // find highest index among instances
        const maxIndex = Math.max(
          ...instances.map((instance) => instance.ptIndex)
        );

        // check if the ptIndex values of all instances are equal
        const allInstanceIndicesEqual = instances
          .map((instance) => instance.ptIndex === maxIndex)
          .reduce((a, b) => a * b);

        // if not, loop through instances
        // and splice travelPts until the indexes of all instances are equal
        if (!allInstanceIndicesEqual) {
          for (let i = 0; i < instances.length; i += 1) {
            for (
              let splices = maxIndex - instances[i].ptIndex;
              splices > 0;
              splices -= 1
            ) {
              ptLists[instances[i].currentThemeIndex].splice(ptIndex, 0, {
                coords: [
                  ptLists[themeIndex][ptIndex - 1].coords[0],
                  ptLists[themeIndex][ptIndex - 1].coords[1]
                ],
                data: ptLists[instances[i].currentThemeIndex][0].data,
                type: 'travelPt'
              });
            }
          }
        }

        // if they are all equal, then calculate coords for the intPt to be placed
        if (allInstanceIndicesEqual) {
          const prevPt = nodeMatrix[themeIndex][ptIndex - 1];
          const lastPts = instances.map((instance) => {
            const lastPt = ptLists[instance.currentThemeIndex][instance.ptIndex - 1];
            return [lastPt.coords[0], lastPt.coords[1]];
          });
          const rowMinMax = [
            Math.min(...lastPts.map((lastPt) => lastPt[0])),
            Math.max(...lastPts.map((lastPt) => lastPt[0]))
          ];

          const colMax = Math.max(...lastPts.map((lastPt) => lastPt[1]));

          let clear = false;
          let calcdRow; let
            calcdCol;
          while (!clear) {
            calcdRow = Math.round(
              (rowMinMax[1] - rowMinMax[0]) / 2 + rowMinMax[0]
            );

            // test calcdCol values for distance from prevPts
            const farEnough = lastPts
              // eslint-disable-next-line no-loop-func
              .map((lastPt) => Math.sqrt(
                (calcdRow - lastPt[0]) ** 2
                + (colMax - lastPt[1]) ** 2
              ))
              .map((distance) => distance >= canVar.ptSpacing)
              .reduce((a, b) => a * b);
            calcdCol = farEnough ? colMax : colMax + canVar.ptSpacing;
            const coordedIntPtsInLine = ptLists[themeIndex]
              .filter((pt) => pt.type === 'intPt' && pt.coords !== null)
              .map((pt) => pt.coords[1] === colMax);
            const lineContainsIntPtAtColMax = coordedIntPtsInLine.length > 0;
            // const lineContainsIntPtAtColMax = false
            if (
              (prevPt.type === 'intPt' && prevPt.coords[1] === calcdCol)
              || lineContainsIntPtAtColMax
            ) {
              calcdCol = colMax + canVar.ptSpacing;
            }

            if (!nodeMatrix[calcdRow][calcdCol].type) {
              clear = true;
            } else {
              for (let i = 1; i <= canVar.ptSpacing; i += 1) {
                if (!nodeMatrix[calcdRow - i][calcdCol].type) {
                  calcdRow -= i;
                  clear = true;
                } else if (!nodeMatrix[calcdRow + i][calcdCol].type) {
                  calcdRow += i;
                  clear = true;
                }
              }
              if (!clear) throw new Error('still out of spaces');
            }
          }
          if (clear) {
            // set intPt coords in ptLists for all instances
            instances.forEach(
              (instance) => {
                ptLists[instance.currentThemeIndex][instance.ptIndex].coords = [
                  calcdRow,
                  calcdCol
                ];
              }
            );
            // set intPt coords in nodeMatrix for all instances
            nodeMatrix[calcdRow][calcdCol] = ptLists[themeIndex][ptIndex];
          }
        }
      }

      // set endPt coords
      if (ptLists[themeIndex][ptIndex].type === 'endPt') {
        ptLists[themeIndex][ptIndex].coords = endPtCoords.current[themeIndex];
        nodeMatrix[
          endPtCoords.current[themeIndex][0]][endPtCoords.current[themeIndex][1]
        ] = ptLists[themeIndex][ptIndex];
      }

      // set endLabel coords
      if (ptLists[themeIndex][ptIndex].type === 'endLabel') {
        ptLists[themeIndex][ptIndex].coords = [
          endPtCoords.current[themeIndex][0],
          endPtCoords.current[themeIndex][1] + 1
        ];
        nodeMatrix[
          endPtCoords.current[themeIndex][0]][endPtCoords.current[themeIndex][1] + 1
        ] = ptLists[themeIndex][ptIndex];
      }

      if (noGood) break; // checks if noGood is triggered at a lower level
      ptListNullCoordTest = updateNullCoordTest();

      // increment indices
      themeIndex += 1;
      if (themeIndex === ptLists.length) {
        themeIndex = 0;
        ptIndex += 1;
      }
    }
    if (!noGood) {
      removeTravelPts();
      return true;
    }
    return false;
  }

  function calcLineLists(lineMatrix, nodeMatrix) {
    // returns a working lineLists array of arrays
    function initLineLists() {
      const workingLineLists = [];
      for (let theme = 0; theme < themeData.length; theme += 1) {
        const lineList = [];
        for (let node = 0; node < nodeLists.current[theme].length; node += 1) {
          if (
            nodeLists.current[theme][node].type === 'midPt'
            || nodeLists.current[theme][node].type === 'intPt'
          ) {
            lineList.push({
              coords: nodeLists.current[theme][node].coords,
              data: nodeLists.current[theme][node].data.theme,
              type: nodeLists.current[theme][node].type
            });
          } else {
            lineList.push({
              coords: nodeLists.current[theme][node].coords,
              data: nodeLists.current[theme][node].data.title,
              type: nodeLists.current[theme][node].type
            });
          }
        }
        workingLineLists.push(lineList);
      }
      return workingLineLists;
    }

    // populates lineMatrix based on initial state of lineLists
    function populateLineMatrix(workingLineLists) {
      for (let theme = 0; theme < workingLineLists.length; theme += 1) {
        for (let pt = 0; pt < workingLineLists[theme].length; pt += 1) {
          const { coords } = workingLineLists[theme][pt];
          lineMatrix[coords[0]][coords[1]] = workingLineLists[theme][pt];
        }
      }
    }

    // calculates position of linePt (if necessary) and then places it in lineLists and lineMatrix
    function placeLinePt(
      lineList,
      ptIndex,
      connectingSlope
    ) {
      // determine if prevPt and currentPt make a horizontal or vertical box
      function determineOrientation(prevPt, currentPt) {
        const boxWidth = Math.abs(currentPt.coords[1] - prevPt.coords[1]);
        const boxHeight = Math.abs(currentPt.coords[0] - prevPt.coords[0]);

        if (boxHeight > boxWidth) return 0;
        if (boxHeight < boxWidth) return 1;
        // if (boxHeight === boxWidth)
        return 1;
      }

      function convertSlopeToAdjSpacesIndex(slope) {
        if (slope % 2 === 0) return slope / 2;
        if (slope % 2 === 1 && slope < 16) return 8; // to break adjSpaces
        throw new Error(`Invalid slope value: ${slope}`);
      }

      function checkPreferredPath(prefPath, prevPt, targetPt, orientation) {
        // number of spaces between prevPt and targetPt
        const loopLength = Math.abs(targetPt.coords[orientation] - prevPt.coords[orientation])
          - 1;

        const testPath = [];
        for (let pt = 0; pt < loopLength; pt += 1) {
          let currentPt;
          if (testPath.length === 0) {
            currentPt = prevPt;
          } else {
            currentPt = testPath[testPath.length - 1];
          }

          // update adjSpaces for currentPt
          const currentPtCol = currentPt.coords[1];
          const currentPtRow = currentPt.coords[0];
          const adjSpaces = [
            [currentPtRow - 1, currentPtCol],
            [currentPtRow - 1, currentPtCol + 1],
            [currentPtRow, currentPtCol + 1],
            [currentPtRow + 1, currentPtCol + 1],
            [currentPtRow + 1, currentPtCol],
            [currentPtRow + 1, currentPtCol - 1],
            [currentPtRow, currentPtCol - 1],
            [currentPtRow - 1, currentPtCol - 1]
          ];

          // check slope of pt
          const slope = testSlope(currentPt, targetPt);
          let dirIndex;
          if (slope === prefPath[1]) {
            dirIndex = convertSlopeToAdjSpacesIndex(slope);
          } else {
            dirIndex = convertSlopeToAdjSpacesIndex(prefPath[0]);
          }

          // check if considered position is occupied
          if (nodeMatrix[adjSpaces[dirIndex][0]][adjSpaces[dirIndex][1]].data) { return null; }
          testPath.push({
            coords: [adjSpaces[dirIndex][0], adjSpaces[dirIndex][1]],
            data: lineList[0].data
          });
        }
        return testPath;
      }

      function defaultLinePathing(
        prevPt,
        targetPt,
        orientation,
        conSlope
      ) {
        const testPath = [];

        const prevPtRow = prevPt.coords[0];
        const prevPtCol = prevPt.coords[1];
        const targetPtRow = targetPt.coords[0];
        const targetPtCol = targetPt.coords[1];
        const minorRange = orientation === 0
          ? Math.abs(targetPtCol - prevPtCol)
          : Math.abs(targetPtRow - prevPtRow);
        const majorRange = orientation === 0
          ? Math.abs(targetPtRow - prevPtRow)
          : Math.abs(targetPtCol - prevPtCol);

        // test along prefPath1 for furthest open space
        let openValue;
        for (let i = 1; i <= minorRange; i += 1) {
          switch (conSlope) {
            case 1:
            case 3:
              if (nodeMatrix[prevPtRow - i][prevPtCol + i].data) {
                openValue = [prevPtRow - i + 1, prevPtCol + i - 1];
                // ends for loop on next iteration
                i = minorRange + 1;
                break;
              }
              if (
                i === minorRange
                && !nodeMatrix[prevPtRow - i][prevPtCol + i].data
              ) {
                openValue = [prevPtRow - i, prevPtCol + i];
              }
              break;
            case 5:
            case 7:
              if (nodeMatrix[prevPtRow + i][prevPtCol + i].data) {
                openValue = [prevPtRow + i - 1, prevPtCol + i - 1];
                // ends for loop on next iteration
                i = minorRange + 1;
                break;
              }
              if (
                i === minorRange
                && !nodeMatrix[prevPtRow + i][prevPtCol + i].data
              ) {
                openValue = [prevPtRow + i, prevPtCol + i];
              }
              break;
            case 9:
            case 11:
              if (nodeMatrix[prevPtRow + i][prevPtCol - i].data) {
                openValue = [prevPtRow + i - 1, prevPtCol - i + 1];
                // ends for loop on next iteration
                i = minorRange + 1;
                break;
              }
              if (
                i === minorRange
                && !nodeMatrix[prevPtRow + i][prevPtCol - i].data
              ) {
                openValue = [prevPtRow + i, prevPtCol - i];
              }
              break;
            case 13:
            case 15:
              if (nodeMatrix[prevPtRow - i][prevPtCol - i].data) {
                openValue = [prevPtRow - i + 1, prevPtCol - i + 1];
                // ends for loop on next iteration
                i = minorRange + 1;
                break;
              }
              if (
                i === minorRange
                && !nodeMatrix[prevPtRow - i][prevPtCol - i].data
              ) {
                openValue = [prevPtRow - i, prevPtCol - i];
              }
              break;
            default:
              throw new Error(`unexpected conSlope value: ${conSlope}`);
          }
        }
        if (!openValue) {
          throw new Error('openValue undefined!');
        }

        // from that pt along secondLength, check for open lane
        const secondLength = majorRange - minorRange;
        let openRow; let openCol; let firstPt; let
          secondPt;
        switch (conSlope) {
          case 1:
            for (let i = openValue[1]; i >= prevPtCol; i -= 1) {
              for (let j = 1; j < secondLength; j += 1) {
                if (
                  nodeMatrix[openValue[0] + Math.abs(openValue[1] - i) - j][i]
                    .data
                ) { break; }
                if (j === secondLength - 1) {
                  openRow = openValue[0] + Math.abs(openValue[1] - i);
                  openCol = i;
                  firstPt = [openRow, openCol];
                  secondPt = [openRow - secondLength, openCol];
                }
              }
              if (openRow) break;
            }
            if (!openRow) {
              for (let i = 1; i < secondLength; i += 1) {
                if (nodeMatrix[prevPtRow - i][prevPtCol].data) {
                  firstPt = [prevPtRow - i + 1, prevPtCol];
                  secondPt = [prevPtRow - i, prevPtCol + 1];
                }
              }
            }
            if (!firstPt) {
              if (nodeMatrix[openValue[0] - 1][openValue[1]].data) { throw new Error('even bigger problem.'); }
              firstPt = openValue;
              secondPt = [openValue[0] - 1, openValue[1]];
            }
            break;
          case 3:
            for (let i = openValue[0]; i <= prevPtRow; i += 1) {
              for (let j = 1; j < secondLength; j += 1) {
                if (
                  nodeMatrix[i][openValue[1] - Math.abs(openValue[0] - i) + j]
                    .data
                ) { break; }
                if (j === secondLength - 1) {
                  openRow = i;
                  openCol = openValue[1] - Math.abs(openValue[0] - i);
                  firstPt = [openRow, openCol];
                  secondPt = [openRow, openCol + secondLength];
                }
              }
              if (openRow) break;
            }
            if (!openRow) {
              for (let i = 1; i < secondLength; i += 1) {
                if (nodeMatrix[prevPtRow][prevPtCol + i].data) {
                  firstPt = [prevPtRow, prevPtCol + i - 1];
                  secondPt = [prevPtRow - 1, prevPtCol + i];
                }
              }
            }
            break;
          case 5:
            for (let i = openValue[0]; i >= prevPtRow; i -= 1) {
              for (let j = 1; j < secondLength; j += 1) {
                if (
                  nodeMatrix[i][openValue[1] - Math.abs(openValue[0] - i) + j]
                    .data
                ) { break; }
                if (j === secondLength - 1) {
                  openRow = i;
                  openCol = openValue[1] - Math.abs(openValue[0] - i);
                  firstPt = [openRow, openCol];
                  secondPt = [openRow, openCol + secondLength];
                }
              }
              if (openRow) break;
            }
            if (!openRow) {
              for (let i = 1; i < secondLength; i += 1) {
                if (nodeMatrix[prevPtRow][prevPtCol + i].data) {
                  firstPt = [prevPtRow, prevPtCol + i - 1];
                  secondPt = [prevPtRow + 1, prevPtCol + i];
                }
              }
            }
            if (!firstPt) {
              if (nodeMatrix[openValue[0]][openValue[1] + 1].data) { throw new Error('even bigger problem.'); }
              firstPt = openValue;
              secondPt = [openValue[0], openValue[1] + 1];
            }
            break;
          case 7:
            for (let i = openValue[1]; i >= prevPtCol; i -= 1) {
              for (let j = 1; j < secondLength; j += 1) {
                if (
                  nodeMatrix[openValue[0] - Math.abs(openValue[1] - i) + j][i]
                    .data
                ) { break; }
                if (j === secondLength - 1) {
                  openRow = openValue[0] - Math.abs(openValue[1] - i);
                  openCol = i;
                  firstPt = [openRow, openCol];
                  secondPt = [openRow + secondLength, openCol];
                }
              }
              if (openRow) break;
            }
            if (!openRow) {
              for (let i = 1; i < secondLength; i += 1) {
                if (nodeMatrix[prevPtRow + i][prevPtCol].data) {
                  firstPt = [prevPtRow + i - 1, prevPtCol];
                  secondPt = [prevPtRow + i, prevPtCol + 1];
                }
              }
            }
            if (!firstPt) {
              if (nodeMatrix[openValue[0] + 1][openValue[1]].data) { throw new Error('even bigger problem.'); }
              firstPt = openValue;
              secondPt = [openValue[0] + 1, openValue[1]];
            }
            break;
          case 9:
            for (let i = openValue[1]; i <= prevPtCol; i += 1) {
              for (let j = 1; j < secondLength; j += 1) {
                if (
                  nodeMatrix[openValue[0] - Math.abs(openValue[1] - i) + j][i]
                    .data
                ) { break; }
                if (j === secondLength - 1) {
                  openRow = openValue[0] - Math.abs(openValue[1] - i);
                  openCol = i;
                  firstPt = [openRow, openCol];
                  secondPt = [openRow + secondLength, openCol];
                }
              }
              if (openRow) break;
            }
            if (!openRow) {
              for (let i = 1; i < secondLength; i += 1) {
                if (nodeMatrix[prevPtRow + i][prevPtCol].data) {
                  firstPt = [prevPtRow + i - 1, prevPtCol];
                  secondPt = [prevPtRow + i, prevPtCol - 1];
                }
              }
            }
            break;
          case 11:
            for (let i = openValue[0]; i >= prevPtRow; i -= 1) {
              for (let j = 1; j < secondLength; j += 1) {
                if (
                  nodeMatrix[i][openValue[1] + Math.abs(openValue[0] - i) - j]
                    .data
                ) { break; }
                if (j === secondLength - 1) {
                  openRow = i;
                  openCol = openValue[1] + Math.abs(openValue[0] - i);
                  firstPt = [openRow, openCol];
                  secondPt = [openRow, openCol - secondLength];
                }
              }
              if (openRow) break;
            }
            if (!openRow) {
              for (let i = 1; i < secondLength; i += 1) {
                if (nodeMatrix[prevPtRow][prevPtCol - i].data) {
                  firstPt = [prevPtRow, prevPtCol - i + 1];
                  secondPt = [prevPtRow + 1, prevPtCol - i];
                }
              }
            }
            break;
          case 13:
            for (let i = openValue[0]; i <= prevPtRow; i += 1) {
              for (let j = 1; j < secondLength; j += 1) {
                if (
                  nodeMatrix[i][openValue[1] + Math.abs(openValue[0] - i) - j]
                    .data
                ) { break; }
                if (j === secondLength - 1) {
                  openRow = i;
                  openCol = openValue[1] + Math.abs(openValue[0] - i);
                  firstPt = [openRow, openCol];
                  secondPt = [openRow, openCol - secondLength];
                }
              }
              if (openRow) break;
            }
            if (!openRow) {
              for (let i = 1; i < secondLength; i += 1) {
                if (nodeMatrix[prevPtRow][prevPtCol - i].data) {
                  firstPt = [prevPtRow, prevPtCol - i + 1];
                  secondPt = [prevPtRow - 1, prevPtCol - i];
                }
              }
            }
            break;
          case 15:
            for (let i = openValue[1]; i <= prevPtCol; i += 1) {
              for (let j = 1; j < secondLength; j += 1) {
                if (
                  nodeMatrix[openValue[0] + Math.abs(openValue[1] - i) - j][i]
                    .data
                ) { break; }
                if (j === secondLength - 1) {
                  openRow = openValue[0] + Math.abs(openValue[1] - i);
                  openCol = i;
                  firstPt = [openRow, openCol];
                  secondPt = [openRow - secondLength, openCol];
                }
              }
              if (openRow) break;
            }
            if (!openRow) {
              for (let i = 1; i < secondLength; i += 1) {
                if (nodeMatrix[prevPtRow - i][prevPtCol].data) {
                  firstPt = [prevPtRow - i + 1, prevPtCol];
                  secondPt = [prevPtRow - i, prevPtCol - 1];
                }
              }
            }
            break;
          default:
            throw new Error(`unexpected conSlope value: ${conSlope}`);
        }

        // push firstPt and secondPt to testPath
        testPath.push({
          coords: [firstPt[0], firstPt[1]],
          data: lineList[0].data
        });
        if (secondPt[0] !== targetPtRow || secondPt[1] !== targetPtCol) {
          testPath.push({
            coords: [secondPt[0], secondPt[1]],
            data: lineList[0].data
          });
        }

        return testPath;
      }

      // handles straight line pathing with basic node detection
      function straightPathing(
        prevPt,
        targetPt,
        conSlope
      ) {
        const testPath = [];

        const prevPtRow = prevPt.coords[0];
        const prevPtCol = prevPt.coords[1];
        const targetPtRow = targetPt.coords[0];
        const targetPtCol = targetPt.coords[1];
        const obstacles = [];
        const sideObstacles1 = [];
        const sideObstacles2 = [];

        // check for obstacles from prevPt to targetPt
        let pathLength; // 1 longer than number of intermediate nodes on path
        switch (conSlope) {
          case 0:
          case 2:
          case 6:
          case 8:
          case 10:
          case 14:
            pathLength = Math.abs(targetPtRow - prevPtRow);
            break;
          case 4:
          case 12:
            pathLength = Math.abs(targetPtCol - prevPtCol);
            break;
          default:
            throw new Error(`unexpected conSlope value: ${conSlope}`);
        }

        // if obstacles exist, push them to obstacles array
        switch (conSlope) {
          case 0:
            for (let i = 1; i < pathLength; i += 1) {
              if (nodeMatrix[prevPtRow - i][prevPtCol].data) {
                obstacles.push(nodeMatrix[prevPtRow - i][prevPtCol].coords);
              }
            }
            break;
          case 2:
            for (let i = 1; i < pathLength; i += 1) {
              if (nodeMatrix[prevPtRow - i][prevPtCol + i].data) {
                obstacles.push(nodeMatrix[prevPtRow - i][prevPtCol + i].coords);
              }
            }
            break;
          case 4:
            for (let i = 1; i < pathLength; i += 1) {
              if (nodeMatrix[prevPtRow][prevPtCol + i].data) {
                obstacles.push(nodeMatrix[prevPtRow][prevPtCol + i].coords);
              }
            }
            break;
          case 6:
            for (let i = 1; i < pathLength; i += 1) {
              if (nodeMatrix[prevPtRow + i][prevPtCol + i].data) {
                obstacles.push(nodeMatrix[prevPtRow + i][prevPtCol + i].coords);
              }
            }
            break;
          case 8:
            for (let i = 1; i < pathLength; i += 1) {
              if (nodeMatrix[prevPtRow + i][prevPtCol].data) {
                obstacles.push(nodeMatrix[prevPtRow + i][prevPtCol].coords);
              }
            }
            break;
          case 10:
            for (let i = 1; i < pathLength; i += 1) {
              if (nodeMatrix[prevPtRow + i][prevPtCol - i].data) {
                obstacles.push(nodeMatrix[prevPtRow + i][prevPtCol - i].coords);
              }
            }
            break;
          case 12:
            for (let i = 1; i < pathLength; i += 1) {
              if (nodeMatrix[prevPtRow][prevPtCol - i].data) {
                obstacles.push(nodeMatrix[prevPtRow][prevPtCol - i].coords);
              }
            }
            break;
          case 14:
            for (let i = 1; i < pathLength; i += 1) {
              if (nodeMatrix[prevPtRow - i][prevPtCol - i].data) {
                obstacles.push(nodeMatrix[prevPtRow - i][prevPtCol - i].coords);
              }
            }
            break;
          default:
            throw new Error(`unexpected conSlope value: ${conSlope}`);
        }

        // if none found, place linePts in a simple path to targetPt
        if (obstacles.length === 0) {
          for (let i = 1; i < pathLength; i += 1) {
            switch (conSlope) {
              case 0:
                testPath.push({
                  coords: [prevPtRow - i, prevPtCol],
                  data: lineList[0].data
                });
                break;
              case 2:
                testPath.push({
                  coords: [prevPtRow - i, prevPtCol + i],
                  data: lineList[0].data
                });
                break;
              case 4:
                testPath.push({
                  coords: [prevPtRow, prevPtCol + i],
                  data: lineList[0].data
                });
                break;
              case 6:
                testPath.push({
                  coords: [prevPtRow + i, prevPtCol + i],
                  data: lineList[0].data
                });
                break;
              case 8:
                testPath.push({
                  coords: [prevPtRow + i, prevPtCol],
                  data: lineList[0].data
                });
                break;
              case 10:
                testPath.push({
                  coords: [prevPtRow + i, prevPtCol - i],
                  data: lineList[0].data
                });
                break;
              case 12:
                testPath.push({
                  coords: [prevPtRow, prevPtCol - i],
                  data: lineList[0].data
                });
                break;
              case 14:
                testPath.push({
                  coords: [prevPtRow - i, prevPtCol - i],
                  data: lineList[0].data
                });
                break;
              default:
                throw new Error(`unexpected conSlope value: ${conSlope}`);
            }
          }
          return testPath;
        }

        // check both adjacent paths for obstacles
        if (obstacles.length > 0) {
          // push any obstacles to sideObstacles1 or sideObstacles2, as appropriate
          switch (conSlope) {
            case 0:
              for (let i = 0; i < pathLength; i += 1) {
                if (nodeMatrix[prevPtRow - i][prevPtCol - 1].data) {
                  sideObstacles1.push(
                    nodeMatrix[prevPtRow - i][prevPtCol - 1].coords
                  );
                }
              }
              for (let j = 0; j < pathLength; j += 1) {
                if (nodeMatrix[prevPtRow - j][prevPtCol + 1].data) {
                  sideObstacles2.push(
                    nodeMatrix[prevPtRow - j][prevPtCol + 1].coords
                  );
                }
              }
              break;
            case 2:
              for (let i = 0; i < pathLength; i += 1) {
                if (nodeMatrix[prevPtRow - i - 1][prevPtCol + i - 1].data) {
                  sideObstacles1.push(
                    nodeMatrix[prevPtRow - i - 1][prevPtCol + i - 1].coords
                  );
                }
              }
              for (let j = 0; j < pathLength; j += 1) {
                if (nodeMatrix[prevPtRow - j + 1][prevPtCol + j + 1].data) {
                  sideObstacles2.push(
                    nodeMatrix[prevPtRow - j + 1][prevPtCol + j + 1].coords
                  );
                }
              }
              break;
            case 4:
              for (let i = 0; i < pathLength; i += 1) {
                if (nodeMatrix[prevPtRow - 1][prevPtCol + i].data) {
                  sideObstacles1.push(
                    nodeMatrix[prevPtRow - 1][prevPtCol + i].coords
                  );
                }
              }
              for (let j = 0; j < pathLength; j += 1) {
                if (nodeMatrix[prevPtRow + 1][prevPtCol + j].data) {
                  sideObstacles2.push(
                    nodeMatrix[prevPtRow + 1][prevPtCol + j].coords
                  );
                }
              }
              break;
            case 6:
              for (let i = 0; i < pathLength; i += 1) {
                if (nodeMatrix[prevPtRow + i - 1][prevPtCol + i + 1].data) {
                  sideObstacles1.push(
                    nodeMatrix[prevPtRow + i - 1][prevPtCol + i + 1].coords
                  );
                }
              }
              for (let j = 0; j < pathLength; j += 1) {
                if (nodeMatrix[prevPtRow + j + 1][prevPtCol + j - 1].data) {
                  sideObstacles2.push(
                    nodeMatrix[prevPtRow + j + 1][prevPtCol + j - 1].coords
                  );
                }
              }
              break;
            case 8:
              for (let i = 0; i < pathLength; i += 1) {
                if (nodeMatrix[prevPtRow + i][prevPtCol + 1].data) {
                  sideObstacles1.push(
                    nodeMatrix[prevPtRow + i][prevPtCol + 1].coords
                  );
                }
              }
              for (let j = 0; j < pathLength; j += 1) {
                if (nodeMatrix[prevPtRow + j][prevPtCol - 1].data) {
                  sideObstacles2.push(
                    nodeMatrix[prevPtRow + j][prevPtCol - 1].coords
                  );
                }
              }
              break;
            case 10:
              for (let i = 0; i < pathLength; i += 1) {
                if (nodeMatrix[prevPtRow + i - 1][prevPtCol - i - 1].data) {
                  sideObstacles1.push(
                    nodeMatrix[prevPtRow + i - 1][prevPtCol - i - 1].coords
                  );
                }
              }
              for (let j = 0; j < pathLength; j += 1) {
                if (nodeMatrix[prevPtRow + j + 1][prevPtCol - j + 1].data) {
                  sideObstacles2.push(
                    nodeMatrix[prevPtRow + j + 1][prevPtCol - j + 1].coords
                  );
                }
              }
              break;
            case 12:
              for (let i = 0; i < pathLength; i += 1) {
                if (nodeMatrix[prevPtRow - 1][prevPtCol - i].data) {
                  sideObstacles1.push(
                    nodeMatrix[prevPtRow + 1][prevPtCol - i].coords
                  );
                }
              }
              for (let j = 0; j < pathLength; j += 1) {
                if (nodeMatrix[prevPtRow + 1][prevPtCol - j].data) {
                  sideObstacles2.push(
                    nodeMatrix[prevPtRow - 1][prevPtCol - j].coords
                  );
                }
              }
              break;
            case 14:
              for (let i = 0; i < pathLength; i += 1) {
                if (nodeMatrix[prevPtRow - i + 1][prevPtCol - i - 1].data) {
                  sideObstacles1.push(
                    nodeMatrix[prevPtRow - i + 1][prevPtCol - i - 1].coords
                  );
                }
              }
              for (let j = 0; j < pathLength; j += 1) {
                if (nodeMatrix[prevPtRow - j - 1][prevPtCol - j + 1].data) {
                  sideObstacles2.push(
                    nodeMatrix[prevPtRow - j - 1][prevPtCol - j + 1].coords
                  );
                }
              }
              break;
            default:
              throw new Error(`unexpected conSlope value: ${conSlope}`);
          }

          let firstPt; let secondPt; let thirdPt; let
            fourthPt;
          const firstObstacle = obstacles[0];
          const lastObstacle = obstacles[obstacles.length - 1];
          let sidePath1 = sideObstacles1.length === 0 && sideObstacles2.length !== 0;
          let sidePath2 = sideObstacles1.length !== 0 && sideObstacles2.length === 0;

          // if both paths are open, randomly pick a path to travel
          if (sideObstacles1.length === 0 && sideObstacles2.length === 0) {
            const randomRoll = Math.round(Math.random()) + 1;
            if (randomRoll === 1) {
              sidePath1 = true;
              sidePath2 = false;
            }
            if (randomRoll === 2) {
              sidePath1 = false;
              sidePath2 = true;
            }
          }

          // if one path is open, travel that path
          if (sidePath1) {
            switch (conSlope) {
              case 0:
                firstPt = [firstObstacle[0] + 1, firstObstacle[1]];
                secondPt = [firstObstacle[0], firstObstacle[1] - 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0], lastObstacle[1] - 1]; }
                fourthPt = [lastObstacle[0] - 1, lastObstacle[1]];
                break;
              case 2:
                firstPt = [firstObstacle[0] + 1, firstObstacle[1] - 1];
                secondPt = [firstObstacle[0] - 1, firstObstacle[1] - 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] - 1, lastObstacle[1] - 1]; }
                fourthPt = [lastObstacle[0] - 1, lastObstacle[1] + 1];
                break;
              case 4:
                firstPt = [firstObstacle[0], firstObstacle[1] - 1];
                secondPt = [firstObstacle[0] - 1, firstObstacle[1]];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] - 1, lastObstacle[1]]; }
                fourthPt = [lastObstacle[0], lastObstacle[1] + 1];
                break;
              case 6:
                firstPt = [firstObstacle[0] - 1, firstObstacle[1] - 1];
                secondPt = [firstObstacle[0] - 1, firstObstacle[1] + 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] - 1, lastObstacle[1] + 1]; }
                fourthPt = [lastObstacle[0] + 1, lastObstacle[1] + 1];
                break;
              case 8:
                firstPt = [firstObstacle[0] - 1, firstObstacle[1]];
                secondPt = [firstObstacle[0], firstObstacle[1] + 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0], lastObstacle[1] + 1]; }
                fourthPt = [lastObstacle[0] + 1, lastObstacle[1]];
                break;
              case 10:
                firstPt = [firstObstacle[0] - 1, firstObstacle[1] + 1];
                secondPt = [firstObstacle[0] + 1, firstObstacle[1] + 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] + 1, lastObstacle[1] + 1]; }
                fourthPt = [lastObstacle[0] + 1, lastObstacle[1] - 1];
                break;
              case 12:
                firstPt = [firstObstacle[0], firstObstacle[1] + 1];
                secondPt = [firstObstacle[0] + 1, firstObstacle[1]];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] + 1, lastObstacle[1]]; }
                fourthPt = [lastObstacle[0], lastObstacle[1] - 1];
                break;
              case 14:
                firstPt = [firstObstacle[0] + 1, firstObstacle[1] + 1];
                secondPt = [firstObstacle[0] + 1, firstObstacle[1] - 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] + 1, lastObstacle[1] - 1]; }
                fourthPt = [lastObstacle[0] - 1, lastObstacle[1] - 1];
                break;
              default:
                throw new Error(`unexpected conSlope value: ${conSlope}`);
            }
          }
          if (sidePath2) {
            switch (conSlope) {
              case 0:
                firstPt = [firstObstacle[0] + 1, firstObstacle[1]];
                secondPt = [firstObstacle[0], firstObstacle[1] + 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0], lastObstacle[1] + 1]; }
                fourthPt = [lastObstacle[0] - 1, lastObstacle[1]];
                break;
              case 2:
                firstPt = [firstObstacle[0] + 1, firstObstacle[1] - 1];
                secondPt = [firstObstacle[0] + 1, firstObstacle[1] + 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] + 1, lastObstacle[1] + 1]; }
                fourthPt = [lastObstacle[0] - 1, lastObstacle[1] + 1];
                break;
              case 4:
                firstPt = [firstObstacle[0], firstObstacle[1] - 1];
                secondPt = [firstObstacle[0] + 1, firstObstacle[1]];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] + 1, lastObstacle[1]]; }
                fourthPt = [lastObstacle[0], lastObstacle[1] + 1];
                break;
              case 6:
                firstPt = [firstObstacle[0] - 1, firstObstacle[1] - 1];
                secondPt = [firstObstacle[0] + 1, firstObstacle[1] - 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] + 1, lastObstacle[1] - 1]; }
                fourthPt = [lastObstacle[0] + 1, lastObstacle[1] + 1];
                break;
              case 8:
                firstPt = [firstObstacle[0] - 1, firstObstacle[1]];
                secondPt = [firstObstacle[0], firstObstacle[1] - 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0], lastObstacle[1] - 1]; }
                fourthPt = [lastObstacle[0] + 1, lastObstacle[1]];
                break;
              case 10:
                firstPt = [firstObstacle[0] - 1, firstObstacle[1] + 1];
                secondPt = [firstObstacle[0] - 1, firstObstacle[1] - 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] - 1, lastObstacle[1] - 1]; }
                fourthPt = [lastObstacle[0] + 1, lastObstacle[1] - 1];
                break;
              case 12:
                firstPt = [firstObstacle[0], firstObstacle[1] + 1];
                secondPt = [firstObstacle[0] - 1, firstObstacle[1]];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] - 1, lastObstacle[1]]; }
                fourthPt = [lastObstacle[0], lastObstacle[1] - 1];
                break;
              case 14:
                firstPt = [firstObstacle[0] + 1, firstObstacle[1] + 1];
                secondPt = [firstObstacle[0] - 1, firstObstacle[1] + 1];
                if (obstacles.length > 1) { thirdPt = [lastObstacle[0] - 1, lastObstacle[1] + 1]; }
                fourthPt = [lastObstacle[0] - 1, lastObstacle[1] - 1];
                break;
              default:
                throw new Error(`unexpected conSlope value: ${conSlope}`);
            }
          }

          // if neither path is open, throw new Error(`no open side paths!`)
          if (sideObstacles1.length !== 0 && sideObstacles2.length !== 0) { throw new Error('no open side paths!'); }

          // guard clause for when targetPt is immediately behind final obstacle
          if (targetPtRow === fourthPt[0] && targetPtCol === fourthPt[1]) { fourthPt = null; }

          testPath.push({
            data: lineList[0].data,
            coords: firstPt
          });
          testPath.push({
            data: lineList[0].data,
            coords: secondPt
          });
          if (thirdPt) {
            testPath.push({
              data: lineList[0].data,
              coords: thirdPt
            });
          }
          if (fourthPt) {
            testPath.push({
              data: lineList[0].data,
              coords: fourthPt
            });
          }
        }
        return testPath;
      }

      // takes an array of linePts and places them in lineLists and lineMatrix
      function placePath(pathArray) {
        pathArray
          .reverse()
          .forEach((linePt) => lineList.splice(ptIndex, 0, linePt));
        pathArray
          .reverse()
          .forEach(
            (linePt) => { lineMatrix[linePt.coords[0]][linePt.coords[1]] = linePt; }
          );
      }

      const conSlope = connectingSlope;
      const prevPt = lineList[ptIndex - 1];
      const currentPt = lineList[ptIndex];
      // 0 = vertical, 1 = horizontal or square
      const orientation = determineOrientation(prevPt, currentPt);
      let prefPath1; let prefPath2; let result1; let
        path;
      let result2 = null;
      let result3 = null;

      switch (conSlope) {
        case 0:
          path = straightPathing(
            prevPt,
            currentPt,
            conSlope
          );
          placePath(path);
          break;
        case 1:
          prefPath1 = getPreferredPaths(conSlope).map((prefPath) => prefPath * 2);
          prefPath2 = getPreferredPaths(conSlope)
            .map((prefPath) => prefPath * 2)
            .reverse();

          result1 = checkPreferredPath(
            prefPath1,
            prevPt,
            currentPt,
            orientation
          );
          if (result1 === null) {
            result2 = checkPreferredPath(
              prefPath2,
              prevPt,
              currentPt,
              orientation
            );
          }
          if (result1 === null && result2 === null) {
            result3 = defaultLinePathing(
              prevPt,
              currentPt,
              orientation,
              conSlope
            );
          }
          path = result1 ?? result2 ?? result3;

          // place result
          placePath(path);
          break;
        case 2:
          path = straightPathing(
            prevPt,
            currentPt,
            conSlope
          );
          placePath(path);
          break;
        case 3:
          prefPath1 = getPreferredPaths(conSlope).map((prefPath) => prefPath * 2);
          prefPath2 = getPreferredPaths(conSlope)
            .map((prefPath) => prefPath * 2)
            .reverse();

          result1 = checkPreferredPath(
            prefPath1,
            prevPt,
            currentPt,
            orientation
          );
          if (result1 === null) {
            result2 = checkPreferredPath(
              prefPath2,
              prevPt,
              currentPt,
              orientation
            );
          }
          if (result1 === null && result2 === null) {
            result3 = defaultLinePathing(
              prevPt,
              currentPt,
              orientation,
              conSlope
            );
          }
          path = result1 ?? result2 ?? result3;

          // place result
          placePath(path);
          break;
        case 4:
          path = straightPathing(
            prevPt,
            currentPt,
            conSlope
          );
          placePath(path);
          break;
        case 5:
          prefPath1 = getPreferredPaths(conSlope).map((prefPath) => prefPath * 2);
          prefPath2 = getPreferredPaths(conSlope)
            .map((prefPath) => prefPath * 2)
            .reverse();

          result1 = checkPreferredPath(
            prefPath1,
            prevPt,
            currentPt,
            orientation
          );
          if (result1 === null) {
            result2 = checkPreferredPath(
              prefPath2,
              prevPt,
              currentPt,
              orientation
            );
          }
          if (result1 === null && result2 === null) {
            result3 = defaultLinePathing(
              prevPt,
              currentPt,
              orientation,
              conSlope
            );
          }
          path = result1 ?? result2 ?? result3;

          // place result
          placePath(path);
          break;
        case 6:
          path = straightPathing(
            prevPt,
            currentPt,
            conSlope
          );
          placePath(path);
          break;
        case 7:
          prefPath1 = getPreferredPaths(conSlope).map((prefPath) => prefPath * 2);
          prefPath2 = getPreferredPaths(conSlope)
            .map((prefPath) => prefPath * 2)
            .reverse();

          result1 = checkPreferredPath(
            prefPath1,
            prevPt,
            currentPt,
            orientation
          );
          if (result1 === null) {
            result2 = checkPreferredPath(
              prefPath2,
              prevPt,
              currentPt,
              orientation
            );
          }
          if (result1 === null && result2 === null) {
            result3 = defaultLinePathing(
              prevPt,
              currentPt,
              orientation,
              conSlope
            );
          }
          path = result1 ?? result2 ?? result3;

          // place result
          placePath(path);
          break;
        case 8:
          path = straightPathing(
            prevPt,
            currentPt,
            conSlope
          );
          placePath(path);
          break;
        case 9:
          prefPath1 = getPreferredPaths(conSlope).map((prefPath) => prefPath * 2);
          prefPath2 = getPreferredPaths(conSlope)
            .map((prefPath) => prefPath * 2)
            .reverse();

          result1 = checkPreferredPath(
            prefPath1,
            prevPt,
            currentPt,
            orientation
          );
          if (result1 === null) {
            result2 = checkPreferredPath(
              prefPath2,
              prevPt,
              currentPt,
              orientation
            );
          }
          if (result1 === null && result2 === null) {
            result3 = defaultLinePathing(
              prevPt,
              currentPt,
              orientation,
              conSlope
            );
          }
          path = result1 ?? result2 ?? result3;

          // place result
          placePath(path);
          break;
        case 10:
          path = straightPathing(
            prevPt,
            currentPt,
            conSlope
          );
          placePath(path);
          break;
        case 11:
          prefPath1 = getPreferredPaths(conSlope).map((prefPath) => prefPath * 2);
          prefPath2 = getPreferredPaths(conSlope)
            .map((prefPath) => prefPath * 2)
            .reverse();

          result1 = checkPreferredPath(
            prefPath1,
            prevPt,
            currentPt,
            orientation
          );
          if (result1 === null) {
            result2 = checkPreferredPath(
              prefPath2,
              prevPt,
              currentPt,
              orientation
            );
          }
          if (result1 === null && result2 === null) {
            result3 = defaultLinePathing(
              prevPt,
              currentPt,
              orientation,
              conSlope
            );
          }
          path = result1 ?? result2 ?? result3;

          // place result
          placePath(path);
          break;
        case 12:
          path = straightPathing(
            prevPt,
            currentPt,
            conSlope
          );
          placePath(path);
          break;
        case 13:
          prefPath1 = getPreferredPaths(conSlope).map((prefPath) => prefPath * 2);
          prefPath2 = getPreferredPaths(conSlope)
            .map((prefPath) => prefPath * 2)
            .reverse();

          result1 = checkPreferredPath(
            prefPath1,
            prevPt,
            currentPt,
            orientation
          );
          if (result1 === null) {
            result2 = checkPreferredPath(
              prefPath2,
              prevPt,
              currentPt,
              orientation
            );
          }
          if (result1 === null && result2 === null) {
            result3 = defaultLinePathing(
              prevPt,
              currentPt,
              orientation,
              conSlope
            );
          }
          path = result1 ?? result2 ?? result3;

          // place result
          placePath(path);
          break;
        case 14:
          path = straightPathing(
            prevPt,
            currentPt,
            conSlope
          );
          placePath(path);
          break;
        case 15:
          prefPath1 = getPreferredPaths(conSlope).map((prefPath) => prefPath * 2);
          prefPath2 = getPreferredPaths(conSlope)
            .map((prefPath) => prefPath * 2)
            .reverse();

          result1 = checkPreferredPath(
            prefPath1,
            prevPt,
            currentPt,
            orientation
          );
          if (result1 === null) {
            result2 = checkPreferredPath(
              prefPath2,
              prevPt,
              currentPt,
              orientation
            );
          }
          if (result1 === null && result2 === null) {
            result3 = defaultLinePathing(
              prevPt,
              currentPt,
              orientation,
              conSlope
            );
          }
          path = result1 ?? result2 ?? result3;

          // place result
          placePath(path);
          break;
        default:
          throw new Error(`unexpected value of conSlope (${conSlope})`);
      }
    }

    const workingLineLists = initLineLists();
    populateLineMatrix(workingLineLists);

    // for each el in workingLineLists, test if current pt is adj to previous pt.
    for (let theme = 0; theme < workingLineLists.length; theme += 1) {
      for (let pt = 1; pt < workingLineLists[theme].length; pt += 1) {
        // test if node is adjacent to previous node
        const lastPt = workingLineLists[theme][pt - 1];
        const lastPtCol = lastPt.coords[1];
        const lastPtRow = lastPt.coords[0];
        const currentPt = workingLineLists[theme][pt];
        const currentCol = workingLineLists[theme][pt].coords[1];
        const currentRow = workingLineLists[theme][pt].coords[0];

        const adjSpaces = [
          [lastPtRow - 1, lastPtCol],
          [lastPtRow - 1, lastPtCol + 1],
          [lastPtRow, lastPtCol + 1],
          [lastPtRow + 1, lastPtCol + 1],
          [lastPtRow + 1, lastPtCol],
          [lastPtRow + 1, lastPtCol - 1],
          [lastPtRow, lastPtCol - 1],
          [lastPtRow - 1, lastPtCol - 1]
        ];

        let adjacent = false;
        for (let space = 0; space < adjSpaces.length; space += 1) {
          if (
            (adjSpaces[space][0] === currentRow
              && adjSpaces[space][1] === currentCol)
            || (pt > 0 && pt <= canVar.straightawayLength)
            || (pt
              >= workingLineLists[theme][pt].length
              - 1
              - canVar.straightawayLength
              && pt < workingLineLists[theme][pt].length - 1)
          ) {
            adjacent = true;
          }
        }
        if (!adjacent) {
          const slope = testSlope(lastPt, currentPt, workingLineLists);
          placeLinePt(
            workingLineLists[theme],
            pt,
            slope
          );
        }
      }
    }

    return workingLineLists;
  }

  // -------------------------------------------------------
  // << FUNCTIONS PASSED AS PROPS >>
  // -------------------------------------------------------

  // used in SvgLayer.js, LinesCanvas.js, NodesLabelsCanvas.js, and MenuLayer.js
  function adjustForZoomLevel(
    // eslint-disable-next-line no-shadow
    zoomLevel,
    // eslint-disable-next-line no-shadow
    validZoomLevels,
    // eslint-disable-next-line no-shadow
    baseZoomStats,
    // eslint-disable-next-line no-shadow
    zoomScaleFactors,
    location,
    svgRef,
    // eslint-disable-next-line no-shadow
    canVar,
    menus,
    // eslint-disable-next-line no-shadow
    nodeLists,
    // eslint-disable-next-line no-shadow
    linesCanvasRef,
    // eslint-disable-next-line no-shadow
    nodesLabelsCanvasRef,
    // eslint-disable-next-line no-shadow
    menuLayerRef
  ) {
    // guard clauses
    if (!validZoomLevels.includes(zoomLevel)) { throw new Error(`Invalid zoomLevel value: ${zoomLevel}`); }
    if (!baseZoomStats) { throw new Error('Invalid baseZoomStats value:', baseZoomStats); }
    if (!zoomScaleFactors) { throw new Error('Invalid zoomScaleFactors value:', zoomScaleFactors); }
    if (!canVar) throw new Error('Invalid canVar value:', canVar);

    const bzs = baseZoomStats;
    const zsf = zoomScaleFactors;

    switch (location) {
      case 'Background.js':
      case 'SvgLayer.js':
        if (!svgRef) throw new Error('Invalid svgRef value:', svgRef);

        svgRef.current.style.width = zoomLevel
          ? bzs.width * zsf.cellSize[zoomLevel] : window.innerWidth;
        svgRef.current.style.height = zoomLevel
          ? bzs.height * zsf.cellSize[zoomLevel] : window.innerHeight;
        canVar.width = zoomLevel ? svgRef.current.style.width : window.innerWidth;
        canVar.height = zoomLevel ? svgRef.current.style.height : window.innerHeight;

        if (window.innerWidth > canVar.width) { // centers map if screen is bigger than map
          svgRef.current.style.top = `${(window.innerHeight - canVar.height) / 2
          }px`;
          svgRef.current.style.left = `${(window.innerWidth - canVar.width) / 2
          }px`;
        } else {
          svgRef.current.style.top = 0;
          svgRef.current.style.left = 0;
        }
        break;
      case 'LinesCanvas.js':
      case 'NodesLabelsCanvas.js':
        // guard clauses
        if (!lineStrokeWidth) { throw new Error('Invalid lineStrokeWidth value:', lineStrokeWidth); }

        canVar.cellSize = zoomLevel ? bzs.cellSize * zsf.cellSize[zoomLevel]
          : window.innerWidth / canVar.numCols;
        canVar.width = zoomLevel ? bzs.numCols * bzs.cellSize * zsf.cellSize[zoomLevel]
          : window.innerWidth;
        canVar.height = zoomLevel ? bzs.numRows * bzs.cellSize * zsf.cellSize[zoomLevel]
          : window.innerHeight;

        if (linesCanvasRef) {
          if (window.innerWidth > canVar.width) {
            linesCanvasRef.current.style.top = `${(window.innerHeight - canVar.height) / 2
            }px`;
            linesCanvasRef.current.style.left = `${(window.innerWidth - canVar.width) / 2
            }px`;
          } else {
            linesCanvasRef.current.style.top = 0;
            linesCanvasRef.current.style.left = 0;
          }
        }
        if (nodesLabelsCanvasRef) {
          if (window.innerWidth > canVar.width) {
            nodesLabelsCanvasRef.current.style.top = `${(window.innerHeight - canVar.height) / 2
            }px`;
            nodesLabelsCanvasRef.current.style.left = `${(window.innerWidth - canVar.width) / 2
            }px`;
          } else {
            nodesLabelsCanvasRef.current.style.top = 0;
            nodesLabelsCanvasRef.current.style.left = 0;
          }
        }

        break;
      case 'MenuLayer.js':
        // guard clauses
        if (!menus) throw new Error('Invalid menus value:', menus);
        if (!nodeLists) throw new Error('Invalid nodeLists value:', nodeLists);
        // eslint-disable-next-line no-case-declarations
        const cellSizeScaleFactor = zsf.cellSize[zoomLevel];
        // eslint-disable-next-line no-case-declarations
        const centerMapVertically = (window.innerHeight - bzs.height * zsf.cellSize[zoomLevel]) / 2;
        // eslint-disable-next-line no-case-declarations
        const centerMapHorizontally = (window.innerWidth - bzs.width * zsf.cellSize[zoomLevel]) / 2;

        nodeLists.forEach((nodeList) => nodeList.forEach((node) => {
          const menuById = menus
            .map((menu) => menu.id)
            .indexOf(`detail-card-${node.data.id}`);
          const windowBiggerThanMap = window.innerWidth > bzs.width * zsf.cellSize[zoomLevel];
          const overviewCellSize = window.innerWidth / (canVar.numCols + 1);
          const marginInPx = 20;
          function determineXCoord() {
            let workingXCoord;
            if (!windowBiggerThanMap && zoomLevel) {
              workingXCoord = node.coords[1] * (bzs.cellSize * cellSizeScaleFactor)
                + (bzs.cellSize * cellSizeScaleFactor) / 2
                - node.data.detailCardWidth / 2;
            } else if (windowBiggerThanMap && zoomLevel) {
              workingXCoord = node.coords[1] * (bzs.cellSize * cellSizeScaleFactor)
                + (bzs.cellSize * cellSizeScaleFactor) / 2
                - node.data.detailCardWidth / 2
                + centerMapHorizontally;
            } else if (!windowBiggerThanMap && !zoomLevel) {
              workingXCoord = node.coords[1] * overviewCellSize
                - node.data.detailCardWidth / 2 + overviewCellSize / 2;
            } else if (windowBiggerThanMap && !zoomLevel) {
              workingXCoord = node.coords[1] * overviewCellSize
                - node.data.detailCardWidth / 2 + overviewCellSize / 2;
            }
            // guard clause against canvas right-side overflow
            if (workingXCoord + node.data.detailCardWidth > bzs.width * zsf.cellSize[zoomLevel]) {
              workingXCoord = canVar.width - marginInPx - node.data.detailCardWidth;
            }
            // guard clause against canvas left-side overflow
            if (workingXCoord < 0) {
              workingXCoord = marginInPx;
            }
            return workingXCoord;
          }
          function determineYCoord() {
            let workingYCoord;
            const globalOverviewVerticalAdjust = (window.innerHeight / 2)
              // eslint-disable-next-line no-use-before-define
              - ((((minMaxRow[1] - minMaxRow[0]) / 2) + minMaxRow[0]) * canVar.cellSize);
            if (!windowBiggerThanMap && zoomLevel) {
              workingYCoord = node.coords[0] * (bzs.cellSize * cellSizeScaleFactor)
                - node.data.detailCardHeight;
            } else if (windowBiggerThanMap && zoomLevel) {
              workingYCoord = node.coords[0] * (bzs.cellSize * cellSizeScaleFactor)
                - node.data.detailCardHeight
                + centerMapVertically;
            } else if (!windowBiggerThanMap && !zoomLevel) {
              workingYCoord = node.coords[0] * overviewCellSize
                - node.data.detailCardHeight + globalOverviewVerticalAdjust;
            } else if (windowBiggerThanMap && !zoomLevel) {
              workingYCoord = node.coords[0] * overviewCellSize
                - node.data.detailCardHeight + globalOverviewVerticalAdjust;
            }
            return workingYCoord;
          }

          const xCoord = determineXCoord();
          const yCoord = determineYCoord();
          if (node.type === 'midPt' || node.type === 'intPt') {
            menus[menuById].style.top = yCoord >= 0 ? `${yCoord}px` : `${marginInPx}px`;
            menus[menuById].style.left = `${xCoord}px`;
          }
        }));

        if (window.innerWidth > canVar.width) {
          menuLayerRef.current.style.top = `${centerMapVertically}px`;
          menuLayerRef.current.style.left = `${centerMapHorizontally}px`;
        } else {
          menuLayerRef.current.style.top = 0;
          menuLayerRef.current.style.left = 0;
        }
        break;
      default:
        throw new Error('Input a valid location value:', location);
    }
  }

  // used in LinesCanvas.js, NodesLabelsCanvas.js, and HoverMenu.js
  function categoryToColor(categoryInput) {
    switch (categoryInput) {
      // compliant with visual identity
      case categories[0]:
        return '#B0B3FF';
      case categories[1]:
        return '#F54E20';
      case categories[2]:
        return '#FF2762';
      case categories[3]:
        return '#F2E500';
      case categories[4]:
        return '#39EE69';
      case categories[5]:
        return '#6C4FFF';
      case categories[6]:
        return '#45BBFF';
      default:
        return 'black';
    }
  }

  // -------------------------------------------------------
  // << USEEFFECT, FUNCTION CALLS, AND RETURN >>
  // -------------------------------------------------------

  useEffect(() => {
    if (refreshCount.current === 1) {
      if (window.innerWidth < baseZoomStats.width) {
        zoomTarget.current[0] = (baseZoomStats.width - window.innerWidth) / 2 + 150;
        zoomTarget.current[1] = (baseZoomStats.height - window.innerHeight) / 2 - 700;
        window.scrollTo(zoomTarget.current[0], zoomTarget.current[1]);
      }
    } else if (refreshCount.current > 1) {
      window.scrollTo(
        (zoomTarget.current[0]
          * (baseZoomStats.width * zoomScaleFactors.cellSize[zoomLevel]))
        - window.innerWidth / 2,
        (zoomTarget.current[1]
          * (baseZoomStats.height * zoomScaleFactors.cellSize[zoomLevel]))
        - window.innerHeight / 2
      );
    }
    zoomScaleFactors.cellSize[0] = window.innerWidth / baseZoomStats.width;
    document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    document.getElementById('___gatsby').style.overflowY = 'hidden';
    document.getElementById('gatsby-focus-wrapper').style.overflowY = 'hidden';
  }, [zoomLevel]);

  // FUNCTION CALLS
  let complete = false;
  if (ran.current < 1) {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // set nodeMatrix to an array of arrays with height = numRows and width = numCols
      const nodeMatrix = [];
      for (let row = 0; row < canVar.numRows; row += 1) {
        const colPrep = [];
        for (let col = 0; col < canVar.numCols; col += 1) {
          colPrep.push([]);
        }
        if (nodeMatrix) {
          nodeMatrix.push(colPrep);
        }
      }

      // set lineMatrix to an array of arrays with height = numRows and width = numCols.
      const lineMatrix = [];
      for (let row = 0; row < canVar.numRows; row += 1) {
        const colPrep = [];
        for (let col = 0; col < canVar.numCols; col += 1) {
          colPrep.push([]);
        }
        if (lineMatrix) {
          lineMatrix.push(colPrep);
        }
      }

      nodeMatrixStable.current = nodeMatrix;
      lineMatrixStable.current = lineMatrix;
      nodeLists.current = initNodeLists();
      complete = calcCoords(
        nodeLists.current,
        nodeMatrixStable.current
      );

      if (complete === true) {
        lineLists.current = calcLineLists(
          lineMatrixStable.current,
          nodeMatrixStable.current
        );
        break;
      }
    }
    ran.current += 1;
    refreshCount.current += 1;
  } else {
    refreshCount.current += 1;
  }

  // find min and max row value in nodeList
  // pass to canvases, menus, and svgs
  // for vertically adjusting on zoomLevel 0
  const minRow = Math.min(...nodeLists.current.map(
    (nodeList) => Math.min(...nodeList.map(
      (node) => node.coords[0]
    ))
  ));
  const maxRow = Math.max(...nodeLists.current.map(
    (nodeList) => Math.max(...nodeList.map(
      (node) => node.coords[0]
    ))
  ));
  const minMaxRow = [minRow, maxRow];

  return (
    <HomeLayout>
      <Seo title="PRAUD" />
      <BackgroundLayer
        // eslint-disable-next-line react/jsx-no-bind
        adjustForZoomLevel={adjustForZoomLevel}
        backgroundRef={backgroundRef}
        zoomLevel={zoomLevel}
        validZoomLevels={validZoomLevels}
        baseZoomStats={baseZoomStats}
        zoomScaleFactors={zoomScaleFactors}
        canVar={canVar}
      />
      <LinesCanvas
        lineStrokeWidth={lineStrokeWidth}
        themeData={themeData}
        onOffs={onOffs}
        canVar={canVar}
        nodeLists={nodeLists.current}
        lineLists={lineLists.current}
        lineFocused={lineFocused}
        zoomLevel={zoomLevel}
        validZoomLevels={validZoomLevels}
        baseZoomStats={baseZoomStats}
        zoomScaleFactors={zoomScaleFactors}
        // eslint-disable-next-line react/jsx-no-bind
        adjustForZoomLevel={adjustForZoomLevel}
        linesCanvasRef={linesCanvasRef}
        // eslint-disable-next-line react/jsx-no-bind
        categoryToColor={categoryToColor}
        minMaxRow={minMaxRow}
      />
      <NodesLabelsCanvas
        nodeStrokeWidth={nodeStrokeWidth}
        nodeRadius={nodeRadius}
        themeData={themeData}
        onOffs={onOffs}
        canVar={canVar}
        nodeLists={nodeLists.current}
        lineFocused={lineFocused}
        zoomLevel={zoomLevel}
        validZoomLevels={validZoomLevels}
        baseZoomStats={baseZoomStats}
        zoomScaleFactors={zoomScaleFactors}
        // eslint-disable-next-line react/jsx-no-bind
        adjustForZoomLevel={adjustForZoomLevel}
        nodesLabelsCanvasRef={nodesLabelsCanvasRef}
        // eslint-disable-next-line react/jsx-no-bind
        categoryToColor={categoryToColor}
        nodeMatrix={nodeMatrixStable.current}
        refreshCount={refreshCount}
        minMaxRow={minMaxRow}
      />
      <div className="vertLine" ref={vertLine} />
      <div className="horiLine" ref={horiLine} />
      <SvgLayer
        lineStrokeWidth={lineStrokeWidth}
        onOffs={onOffs}
        canVar={canVar}
        nodeLists={nodeLists.current}
        lineLists={lineLists.current}
        setLineFocused={setLineFocused}
        lineFocusedRef={lineFocusedRef}
        zoomLevel={zoomLevel}
        validZoomLevels={validZoomLevels}
        baseZoomStats={baseZoomStats}
        zoomScaleFactors={zoomScaleFactors}
        setZoomLevel={setZoomLevel}
        zoomLevelRef={zoomLevelRef}
        zoomTarget={zoomTarget}
        // eslint-disable-next-line react/jsx-no-bind
        adjustForZoomLevel={adjustForZoomLevel}
        vertLine={vertLine}
        horiLine={horiLine}
        minMaxRow={minMaxRow}
        menuToggle={menuToggle}
        setMenuToggle={setMenuToggle}
      />
      <MenuLayer
        menuLayerRef={menuLayerRef}
        nodeLists={nodeLists.current}
        canVar={canVar}
        zoomLevel={zoomLevel}
        validZoomLevels={validZoomLevels}
        baseZoomStats={baseZoomStats}
        zoomScaleFactors={zoomScaleFactors}
        // eslint-disable-next-line react/jsx-no-bind
        adjustForZoomLevel={adjustForZoomLevel}
        // eslint-disable-next-line react/jsx-no-bind
        themeData={themeData}
        startPtCoords={startPtCoords}
        endPtCoords={endPtCoords}
        lineFocusedRef={lineFocusedRef}
        lineFocused={lineFocused}
        setLineFocused={setLineFocused}
        minMaxRow={minMaxRow}
        menuToggle={menuToggle}
        isMobile={isMobile}
      />
      <Widgets
        themeData={themeData}
        onOffs={onOffs}
        setOnOffs={setOnOffs}
        zoomLevel={zoomLevel}
        zoomMinMax={zoomMinMax}
        setZoomLevel={setZoomLevel}
        zoomLevelRef={zoomLevelRef}
        validZoomLevels={validZoomLevels}
      />
      <NavigationSet />
    </HomeLayout>
  );
}
export default IndexPage;
