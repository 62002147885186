import React, { useState, useRef } from 'react';

export default function LayersControls(props) {
  const {
    themeData,
    onOffs,
    setOnOffs
  } = props;

  const layersButton = useRef(null);
  const [controlsToggleValue, setControlsToggleValue] = useState(0);

  function handleChange(eventTarget, i) {
    const items = [...onOffs];
    const item = eventTarget;
    items[i] = item;
    setOnOffs(items);
  }

  function toggleMenus() {
    switch (controlsToggleValue) {
      case 0:
        setControlsToggleValue(1);
        layersButton.current.style.marginRight = '1.25rem';
        break;
      case 1:
        setControlsToggleValue(0);
        layersButton.current.style.marginRight = 0;
        break;
      default:
        throw new Error('unexpected value of controlsToggleValue');
    }
  }

  return (
    <div className="Controls">
      <button type="button" className="button__layers" ref={layersButton} onClick={toggleMenus}>
        <svg
          id="a"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 141.31 130.93"
        >
          <path d="M66.18,74.72L2.76,43.01c-3.69-1.84-3.69-7.1,0-8.94L66.18,2.35c2.82-1.41,6.13-1.41,8.94,0l63.42,31.71c3.69,1.84,3.69,7.1,0,8.94l-63.42,31.71c-2.82,1.41-6.13,1.41-8.94,0Z" />
          <path d="M138.55,61.89l-11.52-5.76-51.9,25.95c-2.82,1.41-6.13,1.41-8.94,0L14.29,56.13l-11.52,5.76c-3.69,1.84-3.69,7.1,0,8.94l63.42,31.71c2.82,1.41,6.13,1.41,8.94,0l63.42-31.71c3.69-1.84,3.69-7.1,0-8.94Z" />
          <path d="M138.55,89.42l-11.52-5.76-51.9,25.95c-2.82,1.41-6.13,1.41-8.94,0L14.29,83.66l-11.52,5.76c-3.69,1.84-3.69,7.1,0,8.94l63.42,31.71c2.82,1.41,6.13,1.41,8.94,0l63.42-31.71c3.69-1.84,3.69-7.1,0-8.94Z" />
        </svg>
      </button>
      <div
        className={controlsToggleValue === 1 ? 'ThemeFilter' : 'ThemeFilter hide'}
        id="ThemeFilter"
      >
        <p>Research Lines</p>
        {themeData.map((theme, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className="toggle-item">
            <input type="checkbox" name={`theme${0}`} id={`theme${0}`} defaultChecked={onOffs[i]} onChange={(e) => handleChange(e.target.checked, i)} />
            <label htmlFor={`theme${i}`}>
              <div className={`theme-chip theme-chip-${i}`}>{i + 1}</div>
              {themeData[i].node.title}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
