import React from 'react';
import DetailCardHeader from './DetailCardHeader';
import DetailCardHeroBlock from './DetailCardHeroBlock';

export default function DetailCard(props) {
  const {
    data, themeList, zoomLevel
  } = props;

  function handleButtonClick() {
    document.getElementById(`detail-card-${data.id}`).style.display = 'none';
  }

  return (
    <div
      id={`detail-card-${data.id}`}
      className={zoomLevel ? 'detail-card' : 'detail-card overview-size'}
    >
      <DetailCardHeader data={data} themeList={themeList} />
      <DetailCardHeroBlock data={data} />
      <button type="button" className="close-card-button" onClick={handleButtonClick}>
        X
      </button>
    </div>
  );
}
