/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

function HomeLayout({ children }) {
  return (
    <main
      className="index"
      style={{
        backgroundColor: '#A0A0A0',
        height: '100%'
      }}
    >
      {children}
    </main>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default HomeLayout;
