import React from 'react';
import LayersControls from './LayersControls';
import ZoomBar from './ZoomBar';

export default function Widgets(props) {
  const {
    themeData,
    onOffs,
    setOnOffs,
    zoomLevel,
    zoomMinMax,
    setZoomLevel,
    zoomLevelRef,
    validZoomLevels
  } = props;

  return (
    <div className="widgets">
      <LayersControls
        themeData={themeData}
        onOffs={onOffs}
        setOnOffs={setOnOffs}
      />
      <ZoomBar
        zoomLevel={zoomLevel}
        zoomMinMax={zoomMinMax}
        setZoomLevel={setZoomLevel}
        zoomLevelRef={zoomLevelRef}
        validZoomLevels={validZoomLevels}
      />
    </div>
  );
}
