import React, { useEffect, useRef } from 'react';

export default function SvgLayer(props) {
  const {
    lineStrokeWidth,
    onOffs,
    canVar,
    nodeLists,
    lineLists,
    setLineFocused,
    lineFocusedRef,
    zoomLevel,
    validZoomLevels,
    baseZoomStats,
    zoomScaleFactors,
    setZoomLevel,
    zoomLevelRef,
    zoomTarget,
    adjustForZoomLevel,
    vertLine,
    horiLine,
    minMaxRow,
    menuToggle,
    setMenuToggle
  } = props;

  const svgRef = useRef(null);
  const vzl = validZoomLevels;
  const bzs = baseZoomStats;
  const zsf = zoomScaleFactors;
  const zoomMinMax = [
    Math.min(...validZoomLevels),
    Math.max(...validZoomLevels)
  ];
  const canvasVar = {
    top: 0,
    left: 0,
    x: 0,
    y: 0
  };
  let moving = false;
  const refreshCount = useRef(0);

  function mouseDownHandler(event) {
    svgRef.current.style.cursor = 'grabbing';
    svgRef.current.style.userSelect = 'none';

    canvasVar.left = document.scrollingElement.scrollLeft;
    canvasVar.top = document.scrollingElement.scrollTop;
    canvasVar.x = event.clientX;
    canvasVar.y = event.clientY;

    canvasVar.mouseDown = true;
  }
  function mouseUpHandler() {
    svgRef.current.style.cursor = 'auto';
    canvasVar.mouseDown = false;
  }
  function mouseMoveHandler(event) {
    if (!vertLine.current) vertLine.current.style.left = '0px';
    if (!horiLine.current) horiLine.current.style.top = '0px';
    vertLine.current.style.left = `${event.clientX}px`;
    horiLine.current.style.top = `${event.clientY}px`;

    if (canvasVar.mouseDown) {
      svgRef.current.style.cursor = 'grabbing';
      moving = true;
      const dx = event.clientX - canvasVar.x;
      const dy = event.clientY - canvasVar.y;
      document.scrollingElement.scrollTop = canvasVar.top - dy;
      document.scrollingElement.scrollLeft = canvasVar.left - dx;
    }
  }
  function mouseLeaveHandler() {
    canvasVar.mouseDown = false;
    svgRef.current.style.cursor = 'auto';
  }

  function handleOnTouchStart(event) {
    const touch = event.touches[0];
    canvasVar.left = document.scrollingElement.scrollLeft;
    canvasVar.top = document.scrollingElement.scrollTop;
    canvasVar.x = touch.clientX;
    canvasVar.y = touch.clientY;
    canvasVar.mouseDown = true;
  }
  function handleOnTouchEnd() {
    canvasVar.mouseDown = false;
  }
  function handleOnTouchMove(event) {
    const touch = event.touches[0];
    if (canvasVar.mouseDown) {
      svgRef.current.style.cursor = 'grabbing';
      moving = true;

      const dx = touch.clientX - canvasVar.x;
      const dy = touch.clientY - canvasVar.y;
      document.scrollingElement.scrollTop = canvasVar.top - dy;
      document.scrollingElement.scrollLeft = canvasVar.left - dx;
    }
  }

  function handleLabelClick(e) {
    const theme = e.target.dataset.themeIndex;
    let lineFocusedStage = lineFocusedRef.current;
    if (lineFocusedStage.includes(false)) {
      lineFocusedStage = [true, true, true, true, true, true, true];
    } else {
      lineFocusedStage = [false, false, false, false, false, false, false];
      lineFocusedStage[theme] = true;
    }
    lineFocusedRef.current = lineFocusedStage;
    setLineFocused(lineFocusedStage);
  }

  function handleNodeClick(id) {
    const menu = document.getElementById(`detail-card-${id}`);
    const targetNode = nodeLists.flat().find((node) => node.data.id === id);
    menu.style.display = menu.style.display === 'flex' ? 'none' : 'flex';
    menu.style.visibility = 'hidden';
    targetNode.data.detailCardWidth = menu.getBoundingClientRect().width;
    targetNode.data.detailCardHeight = menu.getBoundingClientRect().height;
    menu.style.visibility = 'visible';
    if (menuToggle === 1) {
      setMenuToggle(2);
    } else {
      setMenuToggle(1);
    }
    Array.from(document.getElementsByClassName('detail-card'))
      .filter((pt) => pt.id !== `detail-card-${id}`)
      .forEach((pt) => {
        pt.style.display = 'none';
        pt.style.visibility = 'hidden';
      });
  }
  function handleNodeMouseEnter() {
    svgRef.current.style.cursor = 'pointer';
  }
  function handleNodeMouseLeave() {
    svgRef.current.style.cursor = 'auto';
  }

  function drawSvgLine() {
    const globalOverviewVerticalAdjust = zoomLevel ? 0 : (window.innerHeight / 2)
      - ((((minMaxRow[1] - minMaxRow[0]) / 2) + minMaxRow[0]) * canVar.cellSize);
    for (let theme = 0; theme < lineLists.length; theme += 1) {
      const newPath = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      let points = '';
      const canWidth = typeof canVar.width === 'string' ? Number(canVar.width.substring(0, canVar.width.length - 2)) : canVar.width;
      const canHeight = typeof canVar.height === 'string' ? Number(canVar.height.substring(0, canVar.height.length - 2)) : canVar.height;
      for (let pt = 0; pt < lineLists[theme].length; pt += 1) {
        const xCoord = lineLists[theme][pt].coords[1]
          * (canWidth / canVar.numCols) + (canWidth / canVar.numCols) / 2;
        const yCoord = lineLists[theme][pt].coords[0]
          * (canHeight / canVar.numRows) + (canHeight / canVar.numRows) / 2
          + globalOverviewVerticalAdjust;
        if (pt === 0) {
          points += `M${xCoord},${yCoord}`; // begin stroke
        } else {
          points += ` L${xCoord},${yCoord}`; // continue stroke
        }
      }
      newPath.setAttribute('class', 'line');
      newPath.setAttribute('d', points);
      newPath.setAttribute('stroke', 'transparent');
      newPath.style.strokeWidth = lineStrokeWidth + 5;
      newPath.setAttribute('fill', 'none');
      newPath.setAttribute('stroke-linejoin', 'round');
      newPath.setAttribute('stroke-linecap', 'round');
      newPath.dataset.themeIndex = theme;
      newPath.addEventListener('click', handleLabelClick);

      svgRef.current.appendChild(newPath);
    }
  }
  function drawSvgMidIntPts() {
    const globalOverviewVerticalAdjust = (window.innerHeight / 2)
      - ((((minMaxRow[1] - minMaxRow[0]) / 2) + minMaxRow[0]) * canVar.cellSize);
    const canWidth = typeof canVar.width === 'string' ? Number(canVar.width.substring(0, canVar.width.length - 2)) : canVar.width;
    const canHeight = typeof canVar.height === 'string' ? Number(canVar.height.substring(0, canVar.height.length - 2)) : canVar.height;
    for (let theme = 0; theme < nodeLists.length; theme += 1) {
      for (let pt = 0; pt < nodeLists[theme].length; pt += 1) {
        if (
          nodeLists[theme][pt].type === 'midPt'
          || nodeLists[theme][pt].type === 'intPt'
        ) {
          const newPt = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'rect'
          );

          const classNames = nodeLists[theme][pt].type === 'midPt' ? 'node midPt' : 'node intPt';
          newPt.setAttribute('class', classNames);

          newPt.setAttribute(
            'x',
            zoomLevel
              ? nodeLists[theme][pt].coords[1] * (canWidth / canVar.numCols)
              : nodeLists[theme][pt].coords[1] * (canWidth / canVar.numCols)
                  - (canWidth / canVar.numCols) / 2
          );
          newPt.setAttribute(
            'y',
            zoomLevel
              ? nodeLists[theme][pt].coords[0] * (canHeight / canVar.numRows)
              : nodeLists[theme][pt].coords[0] * (canHeight / canVar.numRows)
                  - (canHeight / canVar.numRows) / 2 + globalOverviewVerticalAdjust
          );

          newPt.setAttribute(
            'width',
            zoomLevel ? (canWidth / canVar.numCols) : (canWidth / canVar.numCols) * 2
          );
          newPt.setAttribute(
            'height',
            zoomLevel ? (canHeight / canVar.numRows) : (canHeight / canVar.numRows) * 2
          );
          newPt.setAttribute('fill', 'transparent');

          newPt.addEventListener('click', () => {
            handleNodeClick(nodeLists[theme][pt].data.id, setMenuToggle);
          });
          newPt.addEventListener('mouseenter', handleNodeMouseEnter);
          newPt.addEventListener('mouseleave', handleNodeMouseLeave);

          svgRef.current.appendChild(newPt);
        }
      }
    }
  }

  function handleOnWheel(event) {
    const zoomLevelMin = zoomMinMax[0];
    const zoomLevelMax = zoomMinMax[1];
    zoomTarget.current = [
      (document.scrollingElement.scrollLeft + event.clientX)
        / (bzs.width * zsf.cellSize[zoomLevel]),
      (document.scrollingElement.scrollTop + event.clientY)
        / (bzs.height * zsf.cellSize[zoomLevel])
    ];

    let zoomLevelChange;
    if (event.deltaY > 0) {
      zoomLevelChange = zoomLevel - 1;
    } else {
      zoomLevelChange = zoomLevel + 1;
    }
    // handles cases of exceeding max or min zoomLevels
    if (zoomLevelChange > zoomLevelMax || zoomLevelChange < zoomLevelMin) {
      zoomLevelChange = zoomLevel;
    }
    zoomLevelRef.current = zoomLevelChange;
    setZoomLevel(zoomLevelChange);
  }

  useEffect(() => {
    adjustForZoomLevel(
      zoomLevel,
      vzl,
      bzs,
      zsf,
      'SvgLayer.js',
      svgRef,
      canVar,
      null,
      null,
      null,
      null,
      null
    );
    if (svgRef.current.lastChild) {
      while (svgRef.current.lastChild) {
        svgRef.current.removeChild(svgRef.current.lastChild);
      }
    }
    drawSvgLine(); // SVG line targets
    drawSvgMidIntPts(nodeLists, zoomLevel); // SVG midPt and intPt targets
    refreshCount.current += 1;
  }, [onOffs, zoomLevel, menuToggle]);

  return (
    <svg
      className="hitTargets"
      id="hitTargets"
      ref={svgRef}
      onMouseDown={mouseDownHandler}
      onMouseUp={mouseUpHandler}
      onMouseMove={mouseMoveHandler}
      onMouseLeave={mouseLeaveHandler}
      onClick={(e) => {
        const labels = Array.from(document.getElementsByClassName('label'));
        const lines = Array.from(document.getElementsByClassName('line'));
        const nodes = Array.from(document.getElementsByClassName('node'));
        const labelsLinesNodes = labels.concat(lines).concat(nodes);
        if (!labelsLinesNodes.includes(e.target) && !moving) {
          const lineFocusedStage = [true, true, true, true, true, true, true];
          setLineFocused(lineFocusedStage);
          Array.from(document.getElementsByClassName('detail-card')).forEach(
            (pt) => {
              pt.style.display = 'none';
            }
          );
        }
        moving = false;
      }}
      onWheel={(e) => {
        handleOnWheel(e);
      }}
      onTouchStart={handleOnTouchStart}
      onTouchEnd={handleOnTouchEnd}
      onTouchMove={handleOnTouchMove}
    />
  );
}
