import React from 'react';

export default function DetailCardHeader(props) {
  const { data, themeList } = props;

  let classNameString = data.theme.length > 1
    ? 'detail-card__header midPt'
    : 'detail-card__header intPt';

  let spanClassNameString;

  function defineStyles(themes) {
    if (themes.length > 1) {
      classNameString += ' background-int';
      spanClassNameString = 'date-tag text-light';
      return;
    }
    switch (themes[0].title) {
      case themeList[0]:
        classNameString += ' background-0';
        spanClassNameString = 'date-tag text-dark';
        break;
      case themeList[1]:
        classNameString += ' background-1';
        spanClassNameString = 'date-tag text-dark';
        break;
      case themeList[2]:
        classNameString += ' background-2';
        spanClassNameString = 'date-tag text-dark';
        break;
      case themeList[3]:
        classNameString += ' background-3';
        spanClassNameString = 'date-tag text-dark';
        break;
      case themeList[4]:
        classNameString += ' background-4';
        spanClassNameString = 'date-tag text-dark';
        break;
      case themeList[5]:
        classNameString += ' background-5';
        spanClassNameString = 'date-tag text-dark';
        break;
      case themeList[6]:
        classNameString += ' background-6';
        spanClassNameString = 'date-tag text-dark';
        break;
      default:
        throw new Error('Unexpected theme value');
    }
  }
  function defineMonthText(monthNum) {
    switch (monthNum) {
      case '01':
        return 'Jan';
      case '02':
        return 'Feb';
      case '03':
        return 'Mar';
      case '04':
        return 'Apr';
      case '05':
        return 'May';
      case '06':
        return 'Jun';
      case '07':
        return 'Jul';
      case '08':
        return 'Aug';
      case '09':
        return 'Sep';
      case '10':
        return 'Oct';
      case '11':
        return 'Nov';
      case '12':
        return 'Dec';
      default:
        throw new Error('Invalid month number');
    }
  }

  function defineLineTagClassNameString(theme) {
    switch (theme) {
      case themeList[0]:
        return 'line-tag line-tag-0 text-dark';
      case themeList[1]:
        return 'line-tag line-tag-1 text-light';
      case themeList[2]:
        return 'line-tag line-tag-2 text-light';
      case themeList[3]:
        return 'line-tag line-tag-3 text-dark';
      case themeList[4]:
        return 'line-tag line-tag-4 text-dark';
      case themeList[5]:
        return 'line-tag line-tag-5 text-light';
      case themeList[6]:
        return 'line-tag line-tag-6 text-light';
      default:
        throw new Error('Unexpected theme value');
    }
  }

  const monthNum = data.year[0].match(/-(\d{2})-/)[1];
  const monthText = defineMonthText(monthNum);
  const year = data.year[0].match(/\d{4}/);
  defineStyles(data.theme, themeList);

  return (
    <div className={classNameString}>
      {data.theme
        .sort((a, b) => themeList.indexOf(a.title) - themeList.indexOf(b.title))
        .map((themeEl, i) => {
          const lineTagClassNameString = defineLineTagClassNameString(
            themeEl.title
          );
          return (
            <p
              // eslint-disable-next-line react/no-array-index-key
              key={`${data.id}-${i}`}
              className={lineTagClassNameString}
            >
              {`Line ${themeList.indexOf(themeEl.title) + 1}`}
            </p>
          );
        })}
      <span className={spanClassNameString}>{`${monthText} ${year}`}</span>
    </div>
  );
}
