/* eslint-disable func-names */
import React, { useEffect } from 'react';

export default function LinesCanvas(props) {
  const {
    lineStrokeWidth,
    themeData,
    onOffs,
    canVar,
    nodeLists,
    lineLists,
    lineFocused,
    zoomLevel,
    validZoomLevels,
    baseZoomStats,
    zoomScaleFactors,
    adjustForZoomLevel,
    linesCanvasRef,
    categoryToColor,
    minMaxRow
  } = props;

  const vzl = validZoomLevels;
  const bzs = baseZoomStats;
  const zsf = zoomScaleFactors;

  function getSettingsByZoomLevel(gridLineSpacing) {
    let fontWeight; let
      fontSize;
    const fontFamily = 'source-han-sans-korean, sans-serif';
    switch (zoomLevel) {
      case 0:
        fontWeight = '200';
        fontSize = 4; // in px
        break;
      case 1:
        fontWeight = '200';
        fontSize = 6; // in px
        break;
      case 2:
        fontWeight = '200';
        fontSize = 9; // in px
        break;
      case 3:
        fontWeight = '200';
        fontSize = 12; // in px
        break;
      case 4:
        fontWeight = '200';
        fontSize = 15; // in px
        break;
      case 5:
        fontWeight = '200';
        fontSize = 18; // in px
        break;
      default:
        throw new Error(`Invalid zoomLevel: ${zoomLevel}`);
    }
    return {
      fontSize,
      font: `${fontWeight} ${fontSize}px ${fontFamily}`,
      rowLabelX: 0.125 * canVar.cellSize, // numbers
      rowLabelY: (gridLineSpacing / 2) * canVar.cellSize,
      colLabelX: (gridLineSpacing / 2) * canVar.cellSize, // letters
      colLabelY: 0 * canVar.cellSize + fontSize
    };
  }

  function drawGridLines(ctx) {
    ctx.globalAlpha = 0.9;
    ctx.strokeStyle = '#cccccc';
    ctx.lineWidth = zoomLevel ? 1 : 0.5;
    const numRows = bzs.height * zsf.cellSize[zoomLevel] > window.innerHeight
      ? canVar.numRows
      : window.innerHeight / (bzs.cellSize * zsf.cellSize[zoomLevel]);
    const numCols = bzs.width * zsf.cellSize[zoomLevel] > window.innerWidth
      ? canVar.numCols
      : window.innerWidth / (bzs.cellSize * zsf.cellSize[zoomLevel]);

    for (let row = 0; row < numRows; row += 7) {
      ctx.beginPath();
      ctx.moveTo(0, row * bzs.cellSize * zsf.cellSize[zoomLevel]);
      ctx.lineTo(ctx.canvas.width, row * bzs.cellSize * zsf.cellSize[zoomLevel]);
      ctx.stroke();
    }
    for (let col = 0; col < numCols; col += 7) {
      ctx.beginPath();
      ctx.moveTo(col * bzs.cellSize * zsf.cellSize[zoomLevel], 0);
      ctx.lineTo(col * bzs.cellSize * zsf.cellSize[zoomLevel], ctx.canvas.height);
      ctx.stroke();
    }
    ctx.globalAlpha = 1;
  }

  function numberToLetterCount(index) {
    let runningIndex = index;
    const baseChar = 'A'.charCodeAt(0);
    let letters = '';
    do {
      runningIndex -= 1;
      letters = String.fromCharCode(baseChar + (runningIndex % 26)) + letters;
      runningIndex = (runningIndex > 25) ? (runningIndex / 26) : 0;
    } while (runningIndex > 0);
    return letters;
  }

  function drawGridAnnotations(ctx) {
    ctx.globalAlpha = 0.9;
    const gridLineSpacing = 7;
    const numRows = ctx.canvas.height / canVar.cellSize;
    const numCols = ctx.canvas.width / canVar.cellSize;
    const settings = getSettingsByZoomLevel(gridLineSpacing);
    ctx.font = settings.font;
    ctx.fillStyle = '#cccccc';
    ctx.textAlign = 'center';

    if (zoomLevel) {
      let rowCount = 0;
      for (
        let row = 0;
        row <= numRows + gridLineSpacing;
        row += gridLineSpacing
      ) {
        let colCount = 1;
        for (let col = 0; col <= numCols; col += gridLineSpacing) {
          ctx.fillText(
            rowCount,
            col * canVar.cellSize
              + settings.rowLabelX
              + ctx.measureText(rowCount).width / 2,
            row * canVar.cellSize
              + settings.rowLabelY
              + settings.fontSize * 0.25
          );
          ctx.fillText(
            numberToLetterCount(colCount),
            col * canVar.cellSize + settings.colLabelX,
            row * canVar.cellSize + settings.colLabelY
          );
          colCount += 1;
        }
        rowCount += 1;
      }
    }
    ctx.globalAlpha = 1;
  }

  // eslint-disable-next-line no-shadow
  function drawLineList(ctx, lineListsOriginal, lineLists, themeIndex, onOff) {
    if (onOff) {
      ctx.strokeStyle = lineFocused[
        lineListsOriginal.indexOf(lineLists[themeIndex])
      ]
        ? categoryToColor(
          themeData[lineListsOriginal.indexOf(lineLists[themeIndex])].node
            .title
        )
        : 'lightgrey';
      ctx.lineWidth = lineStrokeWidth
        + (zoomLevel - baseZoomStats.baseLevel) * zoomScaleFactors.lineStrokeWidth;
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
      ctx.beginPath();
      for (let pt = 0; pt < lineLists[themeIndex].length; pt += 1) {
        const { coords } = lineLists[themeIndex][pt];
        const overviewCellSize = canVar.width / canVar.numCols;
        const globalOverviewVerticalAdjust = (window.innerHeight / 2
          - ((((minMaxRow[1] - minMaxRow[0]) / 2) + minMaxRow[0]) * overviewCellSize));
        if (pt === 0 && zoomLevel) {
          ctx.moveTo(
            coords[1] * canVar.cellSize + canVar.cellSize / 2,
            coords[0] * canVar.cellSize + canVar.cellSize / 2
          );
        } else if (pt !== 0 && zoomLevel) {
          ctx.lineTo(
            coords[1] * canVar.cellSize + canVar.cellSize / 2,
            coords[0] * canVar.cellSize + canVar.cellSize / 2
          );
        } else if (pt === 0 && !zoomLevel) {
          ctx.moveTo(
            (coords[1]) * overviewCellSize + overviewCellSize / 2,
            (coords[0]) * overviewCellSize + overviewCellSize / 2
            + globalOverviewVerticalAdjust
          );
        } else if (pt !== 0 && !zoomLevel) {
          ctx.lineTo(
            (coords[1]) * overviewCellSize + overviewCellSize / 2,
            (coords[0]) * overviewCellSize + overviewCellSize / 2
            + globalOverviewVerticalAdjust
          );
        }
      }
      ctx.stroke();
    }
  }

  useEffect(() => {
    const canvas = linesCanvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.style.width = zoomLevel
      ? `${String(bzs.width * zsf.cellSize[zoomLevel])}px`
      : `${String(window.innerWidth)}px`;
    canvas.style.height = zoomLevel
      ? `${String(bzs.height * zsf.cellSize[zoomLevel])}px`
      : `${String(window.innerHeight)}px`;
    ctx.canvas.width = zoomLevel
      ? bzs.width * zsf.cellSize[zoomLevel]
      : window.innerWidth;
    ctx.canvas.height = zoomLevel
      ? bzs.height * zsf.cellSize[zoomLevel]
      : window.innerHeight;

    adjustForZoomLevel(
      zoomLevel,
      vzl,
      bzs,
      zsf,
      'LinesCanvas.js',
      null,
      canVar,
      null,
      null,
      linesCanvasRef,
      null,
      null
    );
    if (nodeLists) {
      drawGridLines(ctx);
      drawGridAnnotations(ctx);

      const lineListsSorted = lineLists.slice().sort((a, b) => (
        lineFocused[lineLists.indexOf(a)] - lineFocused[lineLists.indexOf(b)]
      ));
      lineListsSorted.forEach((lineList, i) => {
        drawLineList(ctx, lineLists, lineListsSorted, i, onOffs[i]);
      });
    }
  }, [onOffs, lineFocused, zoomLevel]);

  return (
    <canvas className="linesCanvas" ref={linesCanvasRef}>
      This is a visual canvas element. For a textual view of site information,
      go to the work, news, or about pages.
    </canvas>
  );
}
