import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

export default function DetailCardHeroBlock(props) {
  const { data } = props;

  function defineLocationString() {
    if (data.city === null && data.country === null) {
      return null;
    }
    if (data.city && data.country === null) {
      return `${data.city.title}`;
    }
    if (data.city === null && data.country) {
      return `${data.country.title}`;
    }
    if (data.city && data.country) {
      return `${data.city.title}, ${data.country.title}`;
    }
    return null;
  }

  const projectHref = `/works/${data.slug.current}`;
  const locationString = defineLocationString(data);

  return (
    <Link className="detail-card__hero-block" to={projectHref}>
      <h2 className="title">{data.title}</h2>
      {locationString ? <p className="location">{locationString}</p> : null}
      <GatsbyImage
        className="menu-image"
        image={data.mainImage.asset.gatsbyImageData}
        alt={data.title}
      />
    </Link>
  );
}
