import React, { useEffect } from 'react';
import DetailCard from '../DetailCard';
import StartLabel from '../StartLabel';
import EndLabel from '../EndLabel';

export default function MenuLayer(props) {
  const {
    menuLayerRef,
    nodeLists,
    canVar,
    zoomLevel,
    validZoomLevels,
    baseZoomStats,
    zoomScaleFactors,
    adjustForZoomLevel,
    themeData,
    startPtCoords,
    endPtCoords,
    lineFocusedRef,
    lineFocused,
    setLineFocused,
    minMaxRow,
    menuToggle,
    isMobile
  } = props;

  const vzl = validZoomLevels;
  const bzs = baseZoomStats;
  const zsf = zoomScaleFactors;

  const themeList = themeData.map((theme) => theme.node.title);

  function updateMenuCoords() {
    const menus = Array.from(document.getElementsByClassName('detail-card'));
    adjustForZoomLevel(
      zoomLevel,
      vzl,
      bzs,
      zsf,
      'MenuLayer.js',
      null,
      canVar,
      menus,
      nodeLists,
      null,
      null,
      menuLayerRef
    );
  }

  useEffect(() => {
    updateMenuCoords();
    menuLayerRef.current.style.width = bzs.width * zsf.cellSize[zoomLevel];
  }, [zoomLevel, menuToggle]);

  return (
    <div className="menuLayer" ref={menuLayerRef}>
      {nodeLists.map((nodeList) => nodeList.map((node) => (node.type === 'midPt' || node.type === 'intPt' ? (
        <DetailCard
          key={node.data.id}
          data={node.data}
          themeList={themeList}
          nodeLists={nodeLists}
          zoomLevel={zoomLevel}
        />
      ) : null)))}
      <div className="start-labels">
        {nodeLists.map((nodeList, i) => (
          <StartLabel
            // eslint-disable-next-line react/no-array-index-key
            key={`start-label-${i}`}
            i={i}
            keyProps={{
              startPtCoords,
              canVar,
              zoomLevel,
              bzs,
              zsf,
              lineFocusedRef,
              lineFocused,
              setLineFocused,
              themeList,
              minMaxRow,
              isMobile
            }}
          />
        ))}
      </div>
      {nodeLists.map((nodeList, i) => (
        <EndLabel
          // eslint-disable-next-line react/no-array-index-key
          key={`end-label-${i}`}
          i={i}
          keyProps={{
            endPtCoords,
            canVar,
            zoomLevel,
            bzs,
            zsf,
            lineFocusedRef,
            lineFocused,
            setLineFocused,
            themeList,
            minMaxRow,
            isMobile
          }}
        />
      ))}
    </div>
  );
}
